import {
    faBars,
    faCaretDown,
    faCaretRight,
    faCircleExclamation,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, merge } from 'lodash-es';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Profile, ProfileProvider, useProfile } from '../../profile';
import schema from '../../schema.json';
import BetaBadge from '../betaBadge/BetaBadge';
import Button from '../form/Button';
import NotificationBadge from '../notificationBadge/NotificationBadge';
import logo from './omnidots-logo.svg';
import {
    accountMenuId,
    downloadsMenuId,
    languageMap,
    languagePlaceholder,
    websiteUrl,
} from './utils';

const listIconClasses = 'xl:ml-1.5 h-3 w-3 fill-current';
const menuItemNameClasses =
    'inline-flex h-20 px-6 xl:px-8 items-center whitespace-nowrap cursor-pointer text-menu-color no-underline justify-between';
const subMenuListClasses =
    'divide-y xl:border xl:absolute xl:w-64 xl:group-hover:flex xl:group-hover:flex-col';
const menuItemListClasses = 'inline-flex flex-col group hover:bg-menu-hover xl:border-0';

const flipState = (state) => (state ? 'xl:hidden' : 'hide');

function useTranslatedMenu() {
    const { t } = useTranslation();

    return useMemo(() => {
        const translations = t('MENU', { returnObjects: true });
        return merge([], schema.menu, translations);
    }, [t]);
}

function shouldBeVisible({ checks = [] }, profile) {
    const failedCheck = checks.find((check) => !profile[check]);

    if (failedCheck) {
        return false;
    }

    return true;
}

function filterMenuForProfile(items, profile) {
    const { isProfileIncomplete, readyReportsCount } = profile;
    const showIncompleteIcon = (id) =>
        (isProfileIncomplete || readyReportsCount > 0) && id === accountMenuId;
    const showNotificationBadge = (id) => readyReportsCount > 0 && id === downloadsMenuId;

    return items
        .filter((item) => shouldBeVisible(item, profile))
        .map((item) => ({
            ...item,
            showIncompleteIcon: showIncompleteIcon(item.id),
            notificationCount: (showNotificationBadge(item.id) && readyReportsCount) || 0,
            // Replaces any URL containing the languagePlaceholder string
            // with the active language code.
            url: item.url && item.url.replace(languagePlaceholder, profile.languageCode),
            children: filterMenuForProfile(item.children, profile),
        }));
}

function useMenuForProfile(profile) {
    const menu = useTranslatedMenu();
    return useMemo(() => filterMenuForProfile(menu, profile), [menu, profile]);
}

function LanguageMenuItem({ item }) {
    const { csrfToken } = useProfile();
    return (
        <form action="/i18n/" method="post" className={item}>
            <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken}></input>
            <input name="next" type="hidden" value={window.location.pathname}></input>
            <input name="language" type="hidden" value={item}></input>
            <input
                type="submit"
                value={languageMap[item]}
                className={
                    'h-20 w-full cursor-pointer pl-12 pr-8 text-left text-menu-color xl:w-64 xl:px-8'
                }
            ></input>
        </form>
    );
}
LanguageMenuItem.propTypes = {
    item: PropTypes.string.isRequired,
};

function LanguageMenuList({ code }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <button
                className={menuItemNameClasses}
                onClick={() => setIsOpen((openState) => !openState)}
            >
                {languageMap[code]}
                <FontAwesomeIcon icon={faCaretDown} className={listIconClasses} />
            </button>
            <div className="flex-none">
                <ul className={`${flipState(isOpen)} ${subMenuListClasses}`}>
                    {Object.keys(languageMap).map((item, i) => (
                        <li key={i} className="bg-white hover:bg-menu-hover">
                            <LanguageMenuItem item={item} />
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}
LanguageMenuList.propTypes = {
    code: PropTypes.string,
    language: PropTypes.string,
};

const menuItemShape = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    beta: PropTypes.bool,
    notificationCount: PropTypes.number.isRequired,
    showHelpIcon: PropTypes.bool,
    showIncompleteIcon: PropTypes.bool.isRequired,
    children(...args) {
        return PropTypes.arrayOf(PropTypes.shape(this)).apply(this, args);
    },
};

function SubMenuItem({ id, name, children, url, beta, notificationCount, level = 1, swarmIcon }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <li
            className={`${level === 1 && 'group/sub'} flex flex-col bg-white hover:bg-menu-hover`}
            onClick={() => setIsOpen((openState) => !openState)}
        >
            <a
                className={`${level === 1 ? 'pl-12' : 'pl-20'} ${menuItemNameClasses} xl:order-2`}
                href={url}
                id={id}
            >
                <span className="flex items-center">
                    {!!swarmIcon && <div className={`${swarmIcon} mr-1.5 inline-block h-6 w-8`} />}
                    {name}
                    {beta && <BetaBadge className="ml-1" />}
                    {!!notificationCount && <NotificationBadge number={notificationCount} />}
                </span>
                {!isEmpty(children) && (
                    <>
                        <FontAwesomeIcon
                            icon={faCaretRight}
                            className={`${listIconClasses} hide xl:block`}
                        />
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            className={`${listIconClasses} block xl:hidden`}
                        />
                    </>
                )}
            </a>
            {!isEmpty(children) && (
                <div className="order-2 xl:relative xl:order-1">
                    <ul
                        className={`${flipState(
                            isOpen
                        )} divide-y border xl:absolute xl:left-full xl:-mt-px xl:w-full ${
                            level === 1 && 'xl:group-hover/sub:block'
                        }`}
                    >
                        {children.map((item, i) => (
                            <SubMenuItem {...item} key={i} level={level + 1} />
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}
SubMenuItem.propTypes = {
    ...menuItemShape,
    level: PropTypes.number,
};

function MenuItem({ id, name, children, url, showHelpIcon, showIncompleteIcon }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <a
                className={menuItemNameClasses}
                href={url}
                id={id}
                onClick={() => setIsOpen((openState) => !openState)}
            >
                {!showHelpIcon ? (
                    <div>
                        {showIncompleteIcon && (
                            <button type="button" aria-label="incomplete">
                                <FontAwesomeIcon
                                    icon={faCircleExclamation}
                                    className="h-3 w-3 pb-0.5 pr-1 text-warning xl:pb-0"
                                />
                            </button>
                        )}
                        {name}
                    </div>
                ) : (
                    <FontAwesomeIcon icon={faQuestionCircle} className="h-4 w-4 fill-current" />
                )}
                {!isEmpty(children) && (
                    <FontAwesomeIcon icon={faCaretDown} className={listIconClasses} />
                )}
            </a>
            <div className="flex-none">
                <ul className={`${flipState(isOpen)} ${subMenuListClasses}`}>
                    {children.map((item, i) => (
                        <SubMenuItem {...item} key={i} />
                    ))}
                </ul>
            </div>
        </>
    );
}
MenuItem.propTypes = menuItemShape;

export function Menu({ profile }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { logoutUrlPath, loginUrlPath } = schema;
    const {
        isAuthenticated,
        languageCode,
        resellerData: { isActive, topLogoUrl, companyName },
    } = profile;
    const menu = useMenuForProfile(profile);

    const redirectPath =
        window.location.pathname === logoutUrlPath || loginUrlPath ? '/' : window.location.pathname;

    const handleAuthButton = useCallback(
        () =>
            window.location.replace(
                `${isAuthenticated ? logoutUrlPath : loginUrlPath}?next=${redirectPath}`
            ),
        [isAuthenticated, loginUrlPath, logoutUrlPath, redirectPath]
    );

    return (
        <ProfileProvider profile={profile}>
            <div className="bg-white shadow-menu">
                <nav className="relative z-[98] m-auto flex h-20 max-w-screen-2xl flex-wrap items-stretch justify-between border-gray-outline px-4">
                    <div className="flex flex-[1_1_10%] items-center">
                        <a className="p-1" href={websiteUrl}>
                            <img
                                src={isActive && topLogoUrl ? topLogoUrl : logo}
                                alt={companyName || 'Omnidots'}
                                className="w-52"
                            />
                        </a>
                    </div>
                    <div className="inline-flex items-center xl:hidden">
                        <button
                            className="inline-flex items-center p-2 hover:bg-menu-hover"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <FontAwesomeIcon icon={faBars} className="h-8 w-8" />
                        </button>
                    </div>
                    <div
                        className={`${
                            isOpen ? '' : 'hide'
                        } absolute inset-x-0 top-20 block divide-y border-b bg-white text-lg xl:relative xl:top-0 xl:flex xl:items-stretch xl:divide-y-0 xl:border-0 xl:text-xs`}
                    >
                        <ul
                            className={
                                'box-content flex flex-col items-stretch divide-y xl:flex-grow xl:flex-row xl:divide-y-0'
                            }
                        >
                            {menu.map((item, i) => (
                                <li className={`${menuItemListClasses}`} key={i}>
                                    <MenuItem {...item} />
                                </li>
                            ))}
                            <li className={`${menuItemListClasses} language-menu`}>
                                <LanguageMenuList code={languageCode} />
                            </li>
                        </ul>
                        <div className="flex items-center py-4 pl-4">
                            <Button
                                variant={isAuthenticated ? 'secondary' : 'primary'}
                                className="whitespace-nowrap font-bold hover:bg-[#A6A6A6]"
                                onClick={handleAuthButton}
                                id="logout"
                            >
                                {isAuthenticated ? t('Sign out') : t('Log in')}
                            </Button>
                        </div>
                    </div>
                </nav>
            </div>
        </ProfileProvider>
    );
}
Menu.propTypes = {
    profile: PropTypes.instanceOf(Profile).isRequired,
};
