import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { format, set } from 'date-fns';

import Button from '../../form/Button';
import { minValue, maxValue } from '../../reactForm/fields/utils';

const formRowClasses = 'mb-4 flex items-center justify-between';
const formLabelClasses = 'block text-sm';
const TIME_FORMAT = 'HH:mm';
const MAX_NAME_LENGTH = 10;
const MIN_MAX_ERROR_MESSAGE = interpolate(
    gettext('VALUE_SHOULD_BE_WITHIN_RANGE'),
    { minValue, maxValue },
    true
);

function updateDate(date, time) {
    const [hours, minutes] = time.split(':');
    return set(date, { hours, minutes });
}

function validateLevel(value) {
    if (parseInt(value, 10) === 0) return true;
    return value >= minValue && value <= maxValue;
}

function FormInput({ label, error, ...props }) {
    return (
        <div className={formRowClasses}>
            <label className={formLabelClasses}>{label}</label>
            <div className="flex flex-col">
                <input {...props} className="w-72 rounded-lg border px-3 py-2" />
                {error && <p className="text-red-600">{error}</p>}
            </div>
        </div>
    );
}
FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['text', 'number']),
    maxLength: PropTypes.number,
};

function TimeInput({ label, date, time, onChange, name, error }) {
    return (
        <div className={formRowClasses}>
            <label className={formLabelClasses}>{label}</label>
            <div>
                <div className="flex">
                    <input
                        className="mr-8 w-32 rounded-lg border px-3 py-2"
                        defaultValue={format(date, 'cccc')}
                        disabled
                    />
                    <input
                        className="w-32 cursor-text rounded-lg border py-2 pl-11 pr-3"
                        name={name}
                        onChange={onChange}
                        value={time}
                        type="time"
                    />
                </div>
                {error && <p className="float-right text-red-600">{error}</p>}
            </div>
        </div>
    );
}
TimeInput.propTypes = {
    label: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    time: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
};

function ModalForm({ isOpen, onClose, onSave, data }) {
    const {
        alarmLevel,
        correction,
        name: alarmName,
        warningLevel,
        startDate,
        endDate,
        startDay,
        endDay,
    } = data;
    const [formData, setFormData] = useState({
        alarmName,
        correction,
        alarmLevel,
        warningLevel,
        startDate,
        startDay,
        startTime: format(startDate, TIME_FORMAT),
        endDay,
        endDate,
        endTime: format(endDate, TIME_FORMAT),
    });
    const [errors, setErrors] = useState({});

    const transFormTime = useCallback(() => {
        if (formData.endTime !== format(endDate, TIME_FORMAT)) {
            formData.endDate = updateDate(endDate, formData.endTime);
        }
        if (formData.startTime !== format(startDate, TIME_FORMAT)) {
            formData.startDate = updateDate(startDate, formData.startTime);
        }
        formData.endTime = formData.endTime.replace(':', '');
        formData.startTime = formData.startTime.replace(':', '');
    }, [endDate, formData, startDate]);

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const validate = useCallback(() => {
        const newErrors = {};
        if (!validateLevel(formData.alarmLevel)) newErrors.alarmLevel = MIN_MAX_ERROR_MESSAGE;
        if (!validateLevel(formData.warningLevel)) newErrors.warningLevel = MIN_MAX_ERROR_MESSAGE;

        // Endtime should be earlier than Starttime on the same day.
        const startTime = formData.startTime.replace(':', '');
        const endTime = formData.endTime.replace(':', '');

        if (parseInt(endTime, 10) <= parseInt(startTime, 10) && startDay === endDay) {
            // Should be replaced with specific error.
            newErrors.endTime = gettext('ERROR');
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [
        endDay,
        formData.alarmLevel,
        formData.endTime,
        formData.startTime,
        formData.warningLevel,
        startDay,
    ]);

    const handleSave = useCallback(() => {
        if (validate()) {
            transFormTime();
            onSave(formData);
            onClose();
        }
    }, [validate, transFormTime, onSave, onClose, formData]);

    if (!isOpen) return null;

    return (
        <div className=" fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50 text-xs">
            <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
                <button
                    className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    &#x2715;
                </button>
                <h2 className="mb-4 text-center text-lg font-bold">{gettext('EDIT')}</h2>

                <FormInput
                    label={gettext('LEQ_ALARM_NAME')}
                    name="alarmName"
                    value={formData.alarmName}
                    onChange={handleChange}
                    maxLength={MAX_NAME_LENGTH}
                    error={errors.alarmName}
                />

                <FormInput
                    label={gettext('CORRECTION')}
                    type="number"
                    name="correction"
                    value={formData.correction}
                    onChange={handleChange}
                    error={errors.correction}
                />

                <FormInput
                    label={gettext('ALARM_LEVEL')}
                    type="number"
                    name="alarmLevel"
                    value={formData.alarmLevel}
                    onChange={handleChange}
                    error={errors.alarmLevel}
                />

                <FormInput
                    label={gettext('WARNING_LEVEL')}
                    type="number"
                    name="warningLevel"
                    value={formData.warningLevel}
                    onChange={handleChange}
                    error={errors.warningLevel}
                />

                <TimeInput
                    label={gettext('START_TIME')}
                    date={formData.startDate}
                    time={formData.startTime}
                    onChange={handleChange}
                    name="startTime"
                />

                <TimeInput
                    label={gettext('END_TIME')}
                    date={formData.endDate}
                    time={formData.endTime}
                    onChange={handleChange}
                    name="endTime"
                    error={errors.endTime}
                />

                <div className="float-right">
                    <Button variant="downloadButton" onClick={handleSave} type="button">
                        {gettext('UPDATE')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
ModalForm.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        correction: PropTypes.number.isRequired,
        alarmLevel: PropTypes.number.isRequired,
        warningLevel: PropTypes.number.isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        endDate: PropTypes.instanceOf(Date).isRequired,
        startDay: PropTypes.number.isRequired,
        endDay: PropTypes.number.isRequired,
    }).isRequired,
};

export default ModalForm;
