import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { get, has, isEmpty, pick, some } from 'lodash-es';
import { useController, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useObservableState } from 'observable-hooks';
import { isEmailValid, isPhoneNumberValid, createAlarmRecipientPlaceholder } from '../../../forms';
import { lcFirst } from '../../../utils/formatting';

import { defaultPropTypes, useIsOverridable, useIsOverridden, useWatchObservable } from '../utils';
import FormFieldContainer from './FormFieldContainer';
import { OverridableInput } from './InputField';
import { INPUT_SIZE } from '../../form/Input';
import { useNamingConventions } from '../../../utils/naming-conventions';
import { RowManager, PLACEHOLDER_ROW_STRATEGY } from '../../../utils/row-manager';

export const invalidEmailOrPhoneText = `${gettext('INVALID')} ${lcFirst(
    createAlarmRecipientPlaceholder()
)}.`;

function validateEmailPhone(value) {
    return value === '' || isEmailValid(value) || isPhoneNumberValid(value);
}

function RowElement({ index, name, disabled, checkboxes, row, manager }) {
    const humanIndex = index + 1;
    const {
        formState: { errors },
    } = useFormContext();

    const path = useMemo(() => `${name}.errors[${index}]`, [name, index]);
    const checkboxesErrorPath = `${path}.checkboxes`;
    const hasCheckBoxesError = has(errors, checkboxesErrorPath);

    return (
        <tr>
            <td className="w-56 pr-3">
                {/*
                Because the values of registered fields that are disabled do
                not come back with `getValues()` or `watch()` we have to do a
                hack here that creates an extra fake disabled field when disabled.
                This allows us to simply hide the original field and keep everything
                working as expected.
                */}
                <OverridableInput
                    type="text"
                    placeholder={createAlarmRecipientPlaceholder()}
                    aria-label={`Recipient ${humanIndex}`}
                    size={INPUT_SIZE.MEDIUM}
                    error={!!get(errors, `${path}.address.identity`)}
                    disabled={disabled}
                    overrideName={name}
                    value={row.address.identity}
                    onChange={(e) => {
                        manager.changeRow(index, 'address.identity', e.target.value);
                    }}
                />
                <span className="text-xs text-form-warning">
                    {get(errors, `${path}.address.identity.message`)}
                </span>
            </td>
            {Object.entries(checkboxes).map(([key, value]) => (
                <td
                    className={`w-10 border-l border-[#ccc] text-center align-top ${
                        hasCheckBoxesError && 'bg-form-warning'
                    }`}
                    key={key}
                >
                    <div className="flex h-10 items-center justify-center">
                        <OverridableInput
                            type="checkbox"
                            aria-label={`${value} checkbox for recipient ${humanIndex}`}
                            disabled={disabled}
                            overrideName={name}
                            checked={row[key]}
                            onChange={(e) => {
                                manager.changeRow(index, key, e.target.checked);
                            }}
                        />
                    </div>
                </td>
            ))}
        </tr>
    );
}
RowElement.propTypes = {
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    checkboxes: PropTypes.object.isRequired,
    row: PropTypes.shape({
        address: PropTypes.shape({ identity: PropTypes.string.isRequired }),
    }).isRequired,
    manager: PropTypes.instanceOf(RowManager).isRequired,
};

function validateRow(row, checkboxes) {
    const errors = {};

    const boxes = pick(row, Array.from(checkboxes.keys()));

    const isValid = !row.address.identity || some(boxes);

    if (!isValid) {
        errors.checkboxes = {
            type: 'manual',
            // Not translated as this message is not visible to the user.
            message: 'Select at least one checkbox.',
        };
    }

    if (!validateEmailPhone(row.address.identity)) {
        errors.address = {
            identity: {
                type: 'manual',
                // Not translated as this message is not visible to the user.
                message: invalidEmailOrPhoneText,
            },
        };
    }

    return isEmpty(errors) ? null : errors;
}

function getCheckboxName(settings, index, originalName) {
    const setting = settings?.[index];
    if (!setting) return null;

    return setting.name || originalName;
}

function AlarmRecipientsField({ fieldOptions }) {
    const { name, settings } = fieldOptions;
    const incomingCheckboxes = fieldOptions.settings.checkboxes;
    const label = useNamingConventions(fieldOptions.settings.label);
    const { control, watch } = useFormContext();

    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        defaultValue: settings.default,
        control,
        rules: {
            validate(rows, _formValues) {
                const errorsPerRow = rows.map((row) => validateRow(row, incomingCheckboxes));

                // If one or more rows has an error, we return all the rows, if
                // not we return `true` to indicate a passed validation.
                return some(errorsPerRow) ? errorsPerRow : true;
            },
        },
    });

    const alarmLevelSettings$ = useWatchObservable(watch, 'alarmLevelSettings');
    const alarmLevelSettings = useObservableState(alarmLevelSettings$, []);

    const leqAlarmSettings$ = useWatchObservable(watch, 'leqAlarmSettings');
    const leqAlarmSettings = useObservableState(leqAlarmSettings$, []);

    const ldenGuideline$ = useWatchObservable(watch, 'ldenGuidelineChoiceType');
    const ldenGuideline = useObservableState(ldenGuideline$, []);

    const checkboxes = useMemo(
        () =>
            Object.fromEntries(
                Array.from(incomingCheckboxes).map(([key, originalName], index) => {
                    let checkboxName = originalName;

                    if (key.startsWith('alarmLevel')) {
                        checkboxName = getCheckboxName(alarmLevelSettings, index, originalName);
                    }

                    if (key.startsWith('leqAlarm')) {
                        const lastChar = key.slice(-1);
                        checkboxName = getCheckboxName(leqAlarmSettings, lastChar, originalName);
                    }

                    if (key.startsWith('lden') && ldenGuideline === 'LdenGuidelineNone') {
                        // Lden warning and alarm aren't dynamic and only use the 'originalName'.
                        checkboxName = null;
                    }

                    return [key, checkboxName];
                })
            ),
        [incomingCheckboxes, alarmLevelSettings, leqAlarmSettings, ldenGuideline]
    );

    const overridden = useIsOverridden(name);
    const disabled = useIsOverridable(name) && !overridden;

    const createEmptyField = useCallback(
        () => ({
            address: { identity: '' },
            ...Object.fromEntries(Object.keys(checkboxes).map((key) => [key, false])),
        }),
        [checkboxes]
    );

    const { rows, manager } = RowManager.useRowManager(
        field.value,
        field.onChange,
        Infinity,
        createEmptyField(),
        {
            placeholderRowStrategy: PLACEHOLDER_ROW_STRATEGY.ADD_WHEN_LAST_ROW_IS_DIRTY,
        }
    );

    const filteredCheckboxes = useMemo(
        () => Object.fromEntries(Object.entries(checkboxes).filter((entry) => entry[1] !== null)),
        [checkboxes]
    );

    return (
        <FormFieldContainer
            fieldOptions={fieldOptions}
            widthClass=""
            // If the error is `custom-validator` we don't want to show the generic error
            // message, but instead show errors on the individual rows below.
            showError={error?.type !== 'custom-validator'}
        >
            <div className="mt-24">
                <label htmlFor={`id_${name}`} className="text-[11px]">
                    {label}
                </label>
                <table>
                    <thead>
                        <tr>
                            <th className="p-0"></th>
                            {Object.entries(filteredCheckboxes).map(([key, value]) => (
                                <th className="p-0" key={key}>
                                    <div className="translate-x-[-105px] translate-y-[-119px] rotate-45">
                                        <span className="absolute w-[165px] truncate border-b border-[#ccc] text-right">
                                            {value}
                                        </span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <RowElement
                                row={row}
                                index={index}
                                key={index}
                                name={name}
                                disabled={disabled}
                                checkboxes={filteredCheckboxes}
                                manager={manager}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                <button
                    type="button"
                    onClick={() => !disabled && manager.addRow()}
                    className="cursor-pointer"
                >
                    <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="text-success"
                        title="Add empty field"
                    />
                </button>
            </div>
        </FormFieldContainer>
    );
}
AlarmRecipientsField.propTypes = defaultPropTypes;

export default AlarmRecipientsField;
