import { line } from 'd3-shape';
import { last, matchesProperty, isNil, remove } from 'lodash-es';
import { Observable, Subject, combineLatest, map, startWith, tap } from 'rxjs';
import tippy from 'tippy.js';
import { collectionNotIncludes } from '../utils/regex';
import { dustGraphDataTypesWithoutPM10, getDataType } from './data-types';
import { getMinuteDays, scrollTrigger } from './graph-helpers';

class LegendItem {
    constructor(options) {
        if (!Object.keys(options).includes('type')) {
            throw new Error('Legend item must have a type property.');
        }

        // Assign options + defaults:
        Object.assign(this, {
            clickable: true,
            // Hidden specifies whether or not to draw the data in the graph.
            hidden: false,
            // Available indicates whether the legend item is available.
            available: true,
            ...options,
        });
    }

    isDisabled() {
        return this.hidden;
    }

    onClick() {
        this.legendData.setHidden(this.type, !this.hidden);
    }
}

class PMLegendItem extends LegendItem {
    ASK_BEFORE_ZOOM_QUESTION = gettext('ASK_BEFORE_ZOOM_QUESTION');

    isDisabled() {
        return !this.available || this.hidden;
    }

    getMinutes() {
        // Turns pm101440m into 1440.
        return this.type.substring(this.pmType.length, this.type.length - 1);
    }

    onClick() {
        // This is the onClick handler for PM10 legend items. When executed and
        // the PM10 data is not available it zooms in on the value-time graph to
        // the level where the data for that legend item should be available. If
        // the data is available, the visibility of the data will be toggled.
        if (this.available) {
            super.onClick();
            return;
        }

        if (
            !this.legendData.context.allowedZoomOnClick &&
            // eslint-disable-next-line no-alert, no-restricted-globals
            !confirm(this.ASK_BEFORE_ZOOM_QUESTION)
        ) {
            // User does not want to zoom.
            return;
        }

        this.legendData.context.allowedZoomOnClick = true;

        const graph = this.legendData.context?.valueTimeGraph;

        // In case it we have already been clicked without the valueTimeGraph
        // being initialized or available in our context.
        if (!graph) {
            return;
        }

        const extraLoadedByQuery = 1 + scrollTrigger * 4;
        const maxBuckets = 1000;
        const minutesNeededInView = (this.getMinutes() * maxBuckets) / extraLoadedByQuery;

        graph.zoom.zoomToScale(minutesNeededInView * getMinuteDays());
    }
}

const minuteUnit = gettext('min');
const hourUnit = gettext('hour');

const types = [
    {
        type: 'x',
        title: 'X',
    },
    {
        type: 'y',
        title: 'Y',
    },
    {
        type: 'z',
        title: 'Z',
    },
    {
        type: 'vector',
        title: 'PVS',
    },
    {
        type: 'pm10-information',
        title: '',
        clickable: false,
        header: true,
        information: gettext('PM10_ZOOM_I_TJE'),
    },
    {
        type: 'pm2p5-information',
        title: '',
        clickable: false,
        header: true,
        information: gettext('PM10_ZOOM_I_TJE').replaceAll('PM x', 'PM2.5'),
    },
    ...['10', '2.5'].flatMap((pmType) => [
        {
            type: `pm${pmType.replace('.', 'p')}1m`,
            title: `PM${pmType === '10' ? ' x' : pmType} 1${minuteUnit}`,
            class: PMLegendItem,
            pmType: `pm${pmType.replace('.', 'p')}`,
        },
        {
            type: `pm${pmType.replace('.', 'p')}15m`,
            title: `PM${pmType === '10' ? ' x' : pmType} 15${minuteUnit}`,
            class: PMLegendItem,
            pmType: `pm${pmType.replace('.', 'p')}`,
        },
        {
            type: `pm${pmType.replace('.', 'p')}60m`,
            title: `PM${pmType === '10' ? ' x' : pmType} 1${hourUnit}`,
            class: PMLegendItem,
            pmType: `pm${pmType.replace('.', 'p')}`,
        },
        // Not implemented for now.
        // {
        //     type: 'pm' + pmType.replace('.', 'p') + '600m',
        //     title: `PM${pmType} 10${gettext('hour')}`,
        //     class: PMLegendItem,
        //     pmType: 'pm' + pmType.replace('.', 'p'),
        // },
        {
            type: `pm${pmType.replace('.', 'p')}1440m`,
            title: `PM${pmType === '10' ? ' x' : pmType} 24${hourUnit}`,
            class: PMLegendItem,
            pmType: `pm${pmType.replace('.', 'p')}`,
        },
    ]),
    {
        type: 'm',
        title: gettext('Event'),
    },
];

export class LegendData {
    constructor() {
        this.updated$ = new Subject();

        // Shared context that can for example be used in onClick handlers
        // of legend items.
        this.context = {};

        this.entries = types.map((options) => {
            const LegendItemClass = options.class ?? LegendItem;
            return new LegendItemClass({
                ...options,
                legendData: this,
            });
        });

        this.visibleTypes$ = this.updated$.pipe(
            startWith(null),
            map(() => this.getVisibleTypes())
        );

        this.hiddenTypes$ = this.updated$.pipe(
            startWith(null),
            map(() => this.getHiddenTypes())
        );
    }

    getEntries() {
        return this.entries;
    }

    setHidden(type, hidden) {
        this.findType(type).hidden = hidden;
        this.updated$.next();
    }

    findType(type) {
        return this.getEntries().find((entry) => entry.type === type);
    }

    removeType(type) {
        remove(this.entries, this.findType(type));
    }

    getHiddenTypes() {
        return this.getEntries()
            .filter((entry) => entry.hidden)
            .map((entry) => entry.type);
    }

    getVisibleTypes() {
        return this.getEntries()
            .filter((entry) => !entry.hidden)
            .map((entry) => entry.type);
    }

    isVisible(type) {
        return this.getVisibleTypes().find((t) => t === type);
    }
}

dustGraphDataTypesWithoutPM10.forEach((dustMetaDataType) => {
    types.push({
        type: dustMetaDataType,
        title: getDataType(dustMetaDataType).title,
    });
});

['x', 'y', 'z'].forEach((axis) => {
    types.push({
        type: `vper-${axis}`,
        title: `${axis.toUpperCase()} Vper`,
        line: true,
        lineClasses: `vperline ${axis}`,
        groupClasses: `${axis}`,
    });
});

export class LegendRenderer {
    constructor(graph, excludeTypes$, legendData) {
        this.graph = graph;
        this.excludeTypes$ = excludeTypes$;
        this.legendData = legendData;

        this.drawLine = line();

        const legendWidth = 200;
        this.backgroundMargin = 6;
        this.identifierRectSize = 8;
        this.x = this.graph.graphWidth - legendWidth;
        this.y = 12;

        // Add the legend to the graph element of the graph.
        this.element = graph.graph.append('g').attr('class', 'legend');

        this.element
            .append('rect')
            .attr('x', this.x - 2)
            .attr('y', this.y - this.backgroundMargin)
            .attr('width', legendWidth)
            .attr('class', 'legend-background');

        const entries$ = this.excludeTypes$.pipe(
            map((excludeTypes) =>
                this.legendData
                    .getEntries()
                    .filter((value) => collectionNotIncludes(excludeTypes, value.type))
            )
        );

        this.renderSubscription = combineLatest([
            entries$,
            this.legendData.updated$
                // Manually trigger the first render with `startWith`.
                .pipe(startWith(null)),
        ])
            .pipe(
                tap(([entries, _updated]) => {
                    this.render(entries);
                })
            )
            .subscribe();
    }

    destroy() {
        this.renderSubscription.unsubscribe();
        this.element.remove();
    }

    static create$(...args) {
        return new Observable((subscriber) => {
            const legendRenderer = new this(...args);

            subscriber.next(legendRenderer);

            // On unsubscribe.
            return () => {
                legendRenderer.destroy();
            };
        });
    }

    render(entries) {
        const itemOffset = 20; // px

        // Calculate top offsets:
        let lastOffset = this.y;
        const topOffsets = [];

        entries.forEach(() => {
            topOffsets.push(lastOffset);

            lastOffset += itemOffset;
        });

        const backgroundHeight =
            last(topOffsets) - this.y + this.identifierRectSize + this.backgroundMargin * 2;

        this.element.selectAll('.legend-background').attr('height', backgroundHeight);

        this.element
            .selectAll('g')
            .data(entries, (d) => d.type)
            .join(
                (enter) => {
                    const group = enter
                        .append('g')
                        .attr('class', (d) => {
                            let classes = d.type;

                            if (d.groupClasses) {
                                classes += ` ${d.groupClasses}`;
                            }
                            return classes;
                        })
                        .classed('clickable', (d) => d.clickable)
                        .on('click', (_event, d) => {
                            if (d.clickable) {
                                d.onClick();
                            }
                        });

                    // Render an indicator if this legend entry is not an header.
                    group
                        .filter((d) => !d.header)
                        .append('rect')
                        .attr('x', this.x)
                        .attr('width', this.identifierRectSize)
                        .attr('height', this.identifierRectSize)
                        .classed('line-background', matchesProperty('line', true));

                    group
                        .filter(matchesProperty('line', true))
                        .append('path')
                        .attr('class', (d) => d.lineClasses);

                    // Add an 'i-tje' if this item has information text.
                    group
                        .filter((d) => !isNil(d.information))
                        .append('text')
                        // 16 = icon width, 2 = small margin.
                        .attr('x', this.graph.graphWidth - (16 + 2))
                        .attr('font-family', 'FontAwesome')
                        .attr('class', 'information-icon')
                        .text('\uf05a')
                        .each((d, i, nodes) => {
                            tippy(nodes[i], {
                                content: d.information,
                                theme: 'omnidots-white-shadow',
                            });
                        });

                    // Add text if title property is filled.
                    group
                        .filter((d) => !!d.title)
                        .append('text')
                        .attr('x', this.x + 15)
                        .text((d) => d.title);

                    return group;
                },
                (update) => update,
                (exit) => {
                    exit.filter((d) => !isNil(d.information))
                        .select('text.information-icon')
                        .each((_d, i, nodes) => {
                            // Destroy Tippy.
                            nodes[i]._tippy.destroy();
                        });

                    return exit.remove();
                }
            )
            // Update locations on every render.
            .call((selection) => {
                selection.select('rect').attr('y', (_d, index) => topOffsets[index]);

                selection.select('path').attr('d', (_d, index) => {
                    const middleOfRect = topOffsets[index] + 4;
                    return this.drawLine([
                        [
                            this.x, // x
                            middleOfRect, // y
                        ],
                        [
                            this.x + this.identifierRectSize, // x
                            middleOfRect, // y
                        ],
                    ]);
                });

                selection
                    .select('text')
                    .attr('y', (_d, index) => topOffsets[index] + this.identifierRectSize);
            })
            .classed('disabled', (d) => d.isDisabled());
    }
}
