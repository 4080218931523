import {
    addHours,
    subMilliseconds,
    startOfDay,
    eachMinuteOfInterval,
    isBefore,
    isWithinInterval,
    isSameSecond,
} from 'date-fns';
import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz';
import PropTypes from 'prop-types';
import useEventListener from '@use-it/event-listener';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { scaleTime } from 'd3-scale';
import { range } from 'lodash-es';
import { useLocale } from '../../utils/date-fns';
import SkeletonLoader from '../skeletonLoader/SkeletonLoader';
import { format as formatDate } from '../../utils/formatting';
import TimeIndicator from './timeIndicator/TimeIndicator';
import {
    LastContactIndicator,
    LastRecordIndicator,
} from './baseContactIndicator/BaseContactIndicator';
import SelectionWrapper from './selectionWrapper/SelectionWrapper';
import {
    getEventInfo,
    calendarTimezone,
    toCalendarDate,
    getDayPeriod,
    generateBlocksPerDay,
    createTimeSpanObject,
    checkCollision,
    dateToMilitary,
    roundDateTo30Minutes,
    extendTemplate,
    formatTemplateData,
} from './utils';
import ModalForm from './modalForm/ModalForm';
import { confirm } from '../confirm/Confirm';

function Block({ Element, block, elementProps }) {
    const id = `${Element.displayName}_${block.dayName}_${block.index}`;
    return <Element key={id} id={id} block={block.props} {...elementProps} {...block.props} />;
}
Block.propTypes = {
    Element: PropTypes.any,
    block: PropTypes.any,
    elementProps: PropTypes.any,
};

function LdenCalendar({
    events = [],
    measuringPointName,
    data,
    inViewStartDate,
    dayStartOffset,
    timezone,
    unit,
    loading,
    currentDate,
    changeTemplate,
    customTemplate,
    buildMode,
    deleteCustomTemplate,
}) {
    const locale = useLocale();
    const graphGridRef = useRef();
    const graphGridWidthRef = useRef();

    const { date: lastContactDate } = useMemo(
        () => getEventInfo(events, 'EventServerContact', timezone),
        [events, timezone]
    );
    const { date: lastRecordDate, uploads: lastRecordUploads } = useMemo(
        () => getEventInfo(events, 'EventLastRecord', timezone),
        [events, timezone]
    );

    const formatTime = useCallback((date) => formatInTimeZone(date, timezone, 'HH:mm'), [timezone]);

    const startOfWeek = useMemo(() => {
        // Step 1: Convert the inViewStartDate (local browser date) to the
        // specified timezone (e.g., 'Europe/Amsterdam').
        const inViewStartDateZoned = utcToZonedTime(inViewStartDate, timezone);

        // Step 2: Get the start of the day and add our 'start of the day offset' to it.
        const startOfWeekZoned = addHours(startOfDay(inViewStartDateZoned), dayStartOffset);

        // Step 3: Convert the zoned start of the week to UTC.
        return toCalendarDate(startOfWeekZoned);
    }, [inViewStartDate, timezone, dayStartOffset]);

    const days = useMemo(
        () =>
            range(7).map((day) => {
                // We cannot use `addDays` as that does not necessarily move the date 24 hours
                // when moving over DST changes.
                const start = addHours(startOfWeek, day * 24);
                const end = subMilliseconds(addHours(start, 24), 1);

                return {
                    start,
                    end,
                    // Creates the name of the day in the locale provided.
                    name: formatInTimeZone(start, calendarTimezone, 'cccc', { locale }),
                    // Creates a unique key that looks like `2022-365` that can be used in React.
                    key: formatInTimeZone(start, calendarTimezone, 'yyyy_D', {
                        useAdditionalDayOfYearTokens: true,
                    }),
                };
            }),
        [startOfWeek, locale]
    );

    // Create time columns by dividing the first day in 'days' into 60-minute intervals
    // and formatting each interval's hour in the calendar timezone.
    const timeColumns = useMemo(
        () =>
            eachMinuteOfInterval(
                {
                    start: days[0].start,
                    end: days[0].end,
                },
                { step: 60 }
            ).map((d) => formatInTimeZone(d, calendarTimezone, 'HHmm')),
        [days]
    );
    const [scalesPerDay, setScalesPerDay] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentBlock, setCurrentBlock] = useState({});
    const [drawingBlock, setDrawingBlock] = useState({});
    // Custom template builder code:
    const [isDragging, setIsDragging] = useState(false);

    const calculateScalesPerDay = useCallback(() => {
        const { width } = graphGridRef.current.getBoundingClientRect();

        // Store the used width to prevent unnecessary recalculation triggered
        // by the resize event while the width stays the same.
        graphGridWidthRef.current = width;

        setScalesPerDay(
            new Map(
                days.map((day) => [day, scaleTime().domain([day.start, day.end]).range([0, width])])
            )
        );
    }, [days, graphGridRef]);

    const deleteBlock = useCallback(
        (block) => {
            changeTemplate((current) => {
                const i = current.findIndex((item) =>
                    isSameSecond(item.startDate, block.startDate)
                );
                if (i >= 0) current.splice(i, 1);
                return [...current];
            });
            setCurrentBlock({});
        },
        [changeTemplate]
    );

    const currentBlockPerDay = useMemo(() => {
        if (!scalesPerDay) return new Map();

        const template = extendTemplate([drawingBlock], 3);
        const templateData = formatTemplateData(template);
        const blockToRender = [
            {
                data: templateData,
                Element: SelectionWrapper,
                elementProps: {
                    formatTime,
                    unit,
                    buildMode,
                    changeTemplate,
                    setShowModal,
                    setCurrentBlock,
                    deleteBlock,
                },
            },
        ];
        return generateBlocksPerDay({
            scalesPerDay,
            timezone,
            blocksToRender: blockToRender,
        });
    }, [
        buildMode,
        changeTemplate,
        deleteBlock,
        drawingBlock,
        formatTime,
        scalesPerDay,
        timezone,
        unit,
    ]);

    const allBlocksPerDay = useMemo(() => {
        if (!scalesPerDay) return new Map();
        const scaleDays = Array.from(scalesPerDay.keys());

        const currentTimeData = createTimeSpanObject(currentDate, currentDate);

        const blocksToRender = [
            {
                data,
                Element: SelectionWrapper,
                elementProps: {
                    formatTime,
                    unit,
                    buildMode,
                    changeTemplate,
                    setShowModal,
                    setCurrentBlock,
                    deleteBlock,
                },
            },
            {
                data: currentTimeData,
                Element: TimeIndicator,
            },
        ];

        if (
            lastContactDate &&
            isWithinInterval(lastContactDate, { start: scaleDays[0].start, end: scaleDays[6].end })
        ) {
            blocksToRender.push({
                data: createTimeSpanObject(lastContactDate, scaleDays[6].end),
                Element: LastContactIndicator,
                elementProps: {
                    title: measuringPointName,
                    date: formatDate(lastContactDate, { timezone }),
                },
            });
        }

        if (lastContactDate && lastRecordDate) {
            blocksToRender.push({
                data: createTimeSpanObject(lastRecordDate, lastContactDate),
                Element: LastRecordIndicator,
                elementProps: {
                    title: measuringPointName,
                    uploadData: lastRecordUploads,
                    date: formatDate(lastRecordDate, { timezone }),
                },
            });
        }

        return generateBlocksPerDay({
            scalesPerDay,
            timezone,
            blocksToRender,
        });
    }, [
        scalesPerDay,
        currentDate,
        data,
        formatTime,
        unit,
        buildMode,
        changeTemplate,
        deleteBlock,
        lastContactDate,
        lastRecordDate,
        timezone,
        measuringPointName,
        lastRecordUploads,
    ]);

    useEffect(() => {
        calculateScalesPerDay();
    }, [calculateScalesPerDay]);

    useEventListener('resize', () => {
        const { width } = graphGridRef.current.getBoundingClientRect();

        if (width !== graphGridWidthRef.current) {
            calculateScalesPerDay();
        }
    });

    const timePeriods = useMemo(
        () => [gettext('DAY'), getDayPeriod(locale, 'evening'), getDayPeriod(locale, 'night')],
        [locale]
    );

    // Function to cleanup drag state.
    const cleanupDragState = useCallback(() => {
        setIsDragging(false);
        document.body.style.cursor = '';
        document.body.style.userSelect = '';
    }, []);

    // Utility to get date from mouse event.
    const getDateFromEvent = useCallback(
        (day, e) => {
            const offsetX = e.clientX - e.currentTarget.getBoundingClientRect().left;
            const scale = scalesPerDay.get(day);
            return utcToZonedTime(scale.invert(offsetX), timezone);
        },
        [scalesPerDay, timezone]
    );

    // Mouse down handler: Create a new object in changeTemplate.
    const blockDragStart = useCallback(
        (day, e) => {
            if (isDragging) return;
            const date = getDateFromEvent(day, e);
            const military = dateToMilitary(date);
            const newBlock = {
                startDate: date,
                endDate: date,
                startDay: military.day,
                startTime: military.time,
                endDay: military.day,
                endTime: military.time,
                name: '',
                warningLevel: 0,
                alarmLevel: 0,
                correction: 0,
            };

            if (!checkCollision(newBlock.startDate, customTemplate)) {
                setIsDragging(true);
                document.body.style.cursor = 'crosshair';
                document.body.style.userSelect = 'none'; // Prevent text selection
                setDrawingBlock(newBlock);
            } else {
                setIsDragging(false);
            }
        },
        [isDragging, getDateFromEvent, customTemplate]
    );

    const setToDate = useCallback(
        // eslint-disable-next-line consistent-return
        (day, e, isDragEnd) => {
            const date = isDragEnd
                ? roundDateTo30Minutes(getDateFromEvent(day, e))
                : getDateFromEvent(day, e);
            const military = dateToMilitary(date);

            if (checkCollision(date, customTemplate)) {
                // Upon collision with another block, this code ends the dragging / creation
                // process of the drawingBlock and updates the custom template.
                // It is needed since blockDragEnd isn't called.
                const updatedBlock = {
                    ...drawingBlock,
                    endDay: military.day + (isBefore(date, drawingBlock.startDate) ? 7 : 0),
                    endDate: date,
                    endTime: military.time,
                };
                changeTemplate((current) => [...current, updatedBlock]);
                setDrawingBlock({});
                return cleanupDragState();
            }

            if (isDragEnd) {
                const startDate = isDragEnd
                    ? roundDateTo30Minutes(drawingBlock.startDate)
                    : drawingBlock.startDate;

                // Prevent block creation by clicking.
                if (isSameSecond(date, startDate)) {
                    return cleanupDragState();
                }

                changeTemplate((current) => {
                    if (checkCollision(date, [drawingBlock])) {
                        cleanupDragState();
                    }

                    const militaryStartDate = dateToMilitary(startDate);
                    const updatedBlock = {
                        ...drawingBlock,
                        startDate,
                        startTime: militaryStartDate.time,
                        endDay: military.day + (isBefore(date, drawingBlock.startDate) ? 7 : 0),
                        endDate: date,
                        endTime: military.time,
                    };

                    setDrawingBlock(updatedBlock);
                    return [...current, updatedBlock];
                });
            } else if (isBefore(date, drawingBlock.startDate)) {
                cleanupDragState();
                setDrawingBlock({});
            } else {
                setDrawingBlock((prevBlock) => ({
                    ...prevBlock,
                    endDay: military.day + (isBefore(date, prevBlock.startDate) ? 7 : 0),
                    endDate: date,
                    endTime: military.time,
                }));
            }
        },
        [changeTemplate, cleanupDragState, customTemplate, drawingBlock, getDateFromEvent]
    );

    // Mouse move handler: Update the end date and time during drag.
    const blockDragMove = useCallback(
        (day, e) => {
            if (isDragging) {
                setToDate(day, e);
            }
        },
        [setToDate, isDragging]
    );

    // Mouse up handler: Finalize the object update and stop dragging.
    const blockDragEnd = useCallback(
        (day, e) => {
            if (isDragging) {
                cleanupDragState();
                setToDate(day, e, true);
                setDrawingBlock({});
            }
        },
        [cleanupDragState, isDragging, setToDate]
    );

    const onMouseLeave = useCallback(() => {
        if (isDragging) {
            cleanupDragState();
        }
    }, [cleanupDragState, isDragging]);

    const updateBlock = useCallback(
        (formData) => {
            const {
                alarmLevel,
                correction,
                alarmName: name,
                warningLevel,
                startDate,
                endDate,
                startTime,
                endTime,
            } = formData;
            changeTemplate((current) => {
                const i = current.findIndex((item) =>
                    isSameSecond(item.startDate, currentBlock.startDate)
                );
                if (i >= 0) {
                    current[i] = {
                        ...current[i],
                        alarmLevel,
                        warningLevel,
                        name,
                        correction,
                        startDate,
                        endDate,
                        startTime,
                        endTime,
                    };
                }
                return [...current];
            });
            setCurrentBlock({});
        },
        [changeTemplate, currentBlock]
    );

    return (
        <div className="flex py-8 text-[9px]">
            <div className={`${buildMode ? 'mt-9' : 'mt-2.5'} w-24 font-bold`}>
                {days.map(({ name, start }, index) => (
                    <div key={index} className="h-20 pb-12 pt-7 text-xs uppercase">
                        {name}
                        {!buildMode && (
                            <div className="text-2xs text-menu-color">
                                {formatDate(start, { timezone: calendarTimezone, hideTime: true })}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="w-full">
                {buildMode && (
                    <button
                        className="relative -top-10 flex w-full justify-end"
                        type="button"
                        aria-label={gettext('DELETE')}
                        onClick={() =>
                            confirm({
                                message: interpolate(gettext('CONFIRM_DELETE_OBJECT'), ['']),
                                onConfirm: () => {
                                    deleteCustomTemplate();
                                    setDrawingBlock({});
                                },
                            })
                        }
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="test h-6 w-6 pr-3 text-error"
                            size="lg"
                        />
                    </button>
                )}
                <div className="relative -top-8 flex justify-around">
                    {timePeriods.map((time, index) => (
                        <div key={index} className="grow text-center uppercase">
                            {time}
                        </div>
                    ))}
                </div>
                <div className="relative -left-2.5 -mt-7 flex">
                    {timeColumns.map((time) => (
                        <div key={time} className="grow">
                            {time}
                        </div>
                    ))}
                </div>
                <div
                    ref={graphGridRef}
                    className="mt-1"
                    onMouseLeave={buildMode ? () => onMouseLeave() : undefined}
                >
                    <div className="relative flex">
                        {Array.from({ length: 24 }).map((_, index) => (
                            <div
                                key={index}
                                className="h-2 grow border-x-[0.5px] border-light-gray px-3"
                            ></div>
                        ))}
                    </div>
                    {days.map((day) => (
                        <div
                            key={day.key}
                            className="relative flex"
                            onMouseDown={buildMode ? (e) => blockDragStart(day, e) : undefined}
                            onMouseMove={buildMode ? (e) => blockDragMove(day, e) : undefined}
                            onMouseUp={buildMode ? (e) => blockDragEnd(day, e) : undefined}
                        >
                            {loading ? (
                                <SkeletonLoader
                                    wrapperClassName={'h-20 flex-1 p-1'}
                                    className={'h-full p-1'}
                                />
                            ) : (
                                <>
                                    {timeColumns.map((time) => (
                                        <div
                                            key={time}
                                            id={`${day.key}_${time}`}
                                            data-testid={`${day.key}_${time}`}
                                            className="h-20 grow border-[0.5px] border-light-gray bg-gray-200 px-3"
                                        ></div>
                                    ))}
                                    {buildMode &&
                                        currentBlockPerDay
                                            .get(day)
                                            ?.map((block, index) => (
                                                <Block
                                                    key={`${day.key}_${index}`}
                                                    Element={block.Element}
                                                    block={{ ...block, dayName: day.name, index }}
                                                    elementProps={block.elementProps}
                                                />
                                            ))}
                                    {allBlocksPerDay.get(day)?.map((block, index) => (
                                        <Block
                                            key={`${day.key}_${index}`}
                                            Element={block.Element}
                                            block={{ ...block, dayName: day.name, index }}
                                            elementProps={block.elementProps}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    ))}
                    {showModal && (
                        <ModalForm
                            isOpen={showModal}
                            onClose={() => setShowModal(false)}
                            onSave={updateBlock}
                            data={currentBlock}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

LdenCalendar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    inViewStartDate: PropTypes.object.isRequired,
    dayStartOffset: PropTypes.number.isRequired,
    timezone: PropTypes.string.isRequired,
    unit: PropTypes.string,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    loading: PropTypes.bool,
    events: PropTypes.array,
    measuringPointName: PropTypes.string,
    buildMode: PropTypes.bool,
    changeTemplate: PropTypes.func,
    customTemplate: PropTypes.array,
    deleteCustomTemplate: PropTypes.func,
};

export default LdenCalendar;
