import PropTypes from 'prop-types';
import { RowManager } from '../../../../utils/row-manager';
import { BaseFormFieldContainer } from '../FormFieldContainer';
import { OverridableWrapper } from '../InputField';
import { AddRowButton, DeleteRowButton } from '../sharedComponents';

export function GenericRowField({
    name: fieldGroupName,
    fields,
    emptyRow,
    maxRows,
    value,
    onChange,
    disabled,
    error,
}) {
    const { rows, manager, rowLimitReached, showDeleteButtons } = RowManager.useRowManager(
        value,
        onChange,
        maxRows,
        emptyRow
    );

    return (
        <div>
            {rows.map((row, index) => (
                <div
                    key={index}
                    className="mb-3.5 flex gap-x-5"
                    data-testid={`${fieldGroupName}-row-${index}`}
                >
                    {fields.map(({ name: fieldName, label, render }) => {
                        const name = `${fieldGroupName}_${fieldName}_${index}`;
                        const id = `id_${name}`;

                        return (
                            <BaseFormFieldContainer
                                name={name}
                                label={label}
                                marginClass=""
                                key={fieldName}
                                error={error?.[index]?.[fieldName]}
                            >
                                <OverridableWrapper
                                    overrideName={fieldGroupName}
                                    disabled={disabled}
                                    render={(props) =>
                                        render({
                                            ...props,
                                            index,
                                            id,
                                            value: row[fieldName],
                                            onChange: (e) => {
                                                let newValue = e.target.value;

                                                if (e.target.type === 'number') {
                                                    newValue = parseInt(e.target.value, 10);
                                                }

                                                manager.changeRow(index, fieldName, newValue);
                                            },
                                        })
                                    }
                                />
                            </BaseFormFieldContainer>
                        );
                    })}
                    {showDeleteButtons && (
                        <DeleteRowButton manager={manager} index={index} disabled={disabled} />
                    )}
                </div>
            ))}

            {!rowLimitReached && <AddRowButton manager={manager} disabled={disabled} />}
        </div>
    );
}
GenericRowField.propTypes = {
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    emptyRow: PropTypes.any.isRequired,
    maxRows: PropTypes.number.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.array,
};
