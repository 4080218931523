import { useQuery, gql, useMutation } from '@apollo/client';
import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash-es';
import useEventListener from '@use-it/event-listener';
import { getClient } from '../../../utils/graphql';
import { BasePage } from '../BasePage';
import CreateRow from './components/CreateRow';
import EditRow from './components/EditRow';
import { NotFoundPage } from '../NotFoundPage';
import { ErrorPage } from '../ErrorPage';
import Button from '../../form/Button';
import {
    ENTITY_TYPE,
    ENTITY_TYPE_TRANSLATIONS,
    PERMISSION_TRANSLATIONS,
    PERMISSION,
} from './utils';
import { getSwarmInfoById } from '../measuringPoint/utils';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';

export const ENTITY_UNKNOWN_ERROR_CODE = 'ENTITY_UNKNOWN';
export const SHARE_ALREADY_EXISTS_ERROR_CODE = 'SHARE_ALREADY_EXISTS';
export const EMAIL_ONLY_FOR_INVITE_ERROR_CODE = 'USE_EMAIL_ONLY_FOR_INVITE';
export const SHOULD_NOT_SHARE_WITH_SELF_ERROR_CODE = 'SHOULD_NOT_SHARE_WITH_SELF';

const TABLE_HEADER_TRANSLATIONS = Object.freeze({
    CREATE_HEADERS: [gettext('PERMISSION'), gettext('ACTIONS')],
    VIEW_HEADERS: [gettext('PERMISSION'), gettext('DELETE')],
});

const MUTATION_ACTION = Object.freeze({
    CREATE: 'Create',
    DELETE: 'Delete',
    UPDATE: 'Update',
});

const SHARE_FRAGMENT = gql`
    fragment ShareTypeFields on ShareType {
        entityId
        entityType
        forUser
        permission
        invite
        sharedBy
    }
`;

const SHARES_QUERY = (entityType) => gql`
    query ($entityId: Int!) {
        shares(entityType: ${entityType}, entityId: $entityId) {
            ...ShareTypeFields
        }
    }
    ${SHARE_FRAGMENT}
`;

const createMutation = (type) => {
    let variablesType = '$entityId: Int!, $forUser: String!, $entityType: EntityType!';
    let variablesValue = 'entityId: $entityId, forUser: $forUser, entityType: $entityType';
    if ([MUTATION_ACTION.CREATE, MUTATION_ACTION.UPDATE].includes(type)) {
        variablesType += ', $permission: Permission!';
        variablesValue += ' permission: $permission';
    }
    return gql`mutation(${variablesType}){
            Share${type}(${variablesValue})
            {
                share${type} {
                    ...ShareTypeFields
                }
            }
        }
        ${SHARE_FRAGMENT}
    `;
};

export const createShareAlreadyExistsText = (user, permission) => {
    if (user && permission) {
        return interpolate(gettext('SHARE_ALREADY_EXISTS_DETAILED_ERROR'), [
            user,
            PERMISSION_TRANSLATIONS[permission],
        ]);
    }

    return gettext('SHARE_ALREADY_EXISTS_ERROR');
};

const sortSharesByCreator = (shares) => {
    const sortedArray = [...shares];
    sortedArray.sort((a, b) => {
        if (a.sharedBy === null) return -1;
        if (b.sharedBy === null) return 1;
        return 0;
    });
    return sortedArray;
};

const getGraphQLErrorExtensionCode = (error) => error.graphQLErrors[0]?.extensions?.code;

const loading = Symbol('loading');

const useQueryGenerator = (entityType) => {
    const { id } = useParams();
    const [shares, setShares] = useState([]);
    const [pendingUpdates, setPendingUpdates] = useState([]);
    const [formError, setFormError] = useState();
    const [createMode, setCreateMode] = useState(false);
    const tableHeader = useMemo(
        () =>
            createMode
                ? TABLE_HEADER_TRANSLATIONS.CREATE_HEADERS
                : TABLE_HEADER_TRANSLATIONS.VIEW_HEADERS,
        [createMode]
    );

    const entityId = entityType === ENTITY_TYPE.USER ? 0 : parseInt(id, 10);

    const [entityName, setEntityName] = useState(null);

    useEffect(() => {
        // Every time the entityType or ID changes we reset the entity name.
        setEntityName(null);

        // For the user share page we dont want to show a name.
        if (entityType === ENTITY_TYPE.USER) {
            return;
        }

        // Query the entity name.
        setEntityName(loading);
        const isProject = entityType === ENTITY_TYPE.PROJECT;
        getSwarmInfoById(id, isProject).then((result) => {
            setEntityName(result.name);
        });
    }, [id, entityType, setEntityName]);

    const { error } = useQuery(SHARES_QUERY(entityType), {
        variables: { entityId },
        onCompleted: (data) => setShares(data.shares ? sortSharesByCreator(data.shares) : []),
    });

    const enableCreateMode = useCallback(() => {
        setCreateMode(true);
    }, []);

    const disableCreateMode = useCallback(() => {
        setCreateMode(false);
        return setFormError();
    }, []);

    const [createShareMutation] = useMutation(createMutation(MUTATION_ACTION.CREATE), {
        update: (
            cache,
            {
                data: {
                    ShareCreate: { shareCreate },
                },
            }
        ) => {
            if (!shareCreate) {
                return;
            }

            cache.modify({
                fields: {
                    shares(existingShares) {
                        return [...existingShares, shareCreate];
                    },
                },
            });
        },
        onError(mutationError) {
            const errorCode = getGraphQLErrorExtensionCode(mutationError);

            if (errorCode === SHARE_ALREADY_EXISTS_ERROR_CODE) {
                return setFormError(createShareAlreadyExistsText());
            }

            if (errorCode === EMAIL_ONLY_FOR_INVITE_ERROR_CODE) {
                return setFormError(gettext('USE_EMAIL_ONLY_FOR_INVITE_ERROR'));
            }

            if (errorCode === SHOULD_NOT_SHARE_WITH_SELF_ERROR_CODE) {
                return setFormError(gettext('SHOULD_NOT_SHARE_WITH_SELF_ERROR'));
            }

            return setFormError(gettext('ERROR_PAGE_TITLE'));
        },
        onCompleted() {
            disableCreateMode();
        },
    });

    const [updateShareMutation] = useMutation(createMutation(MUTATION_ACTION.UPDATE), {
        onCompleted: (updatedData) => {
            setPendingUpdates((current) =>
                current.filter(
                    (pendingShare) =>
                        pendingShare.forUser !== updatedData.ShareUpdate.shareUpdate.forUser
                )
            );
        },
    });

    const [deleteShareMutation] = useMutation(createMutation(MUTATION_ACTION.DELETE), {
        update: (
            cache,
            {
                data: {
                    ShareDelete: { shareDelete },
                },
            }
        ) => {
            if (!shareDelete) {
                return;
            }

            cache.modify({
                fields: {
                    shares(_existingShares, { DELETE }) {
                        return DELETE;
                    },
                },
            });
        },
    });

    const createShare = useCallback(
        (forUser, permission) => {
            if (shares.length) {
                const found = shares.find(
                    (share) => share.forUser.toLowerCase() === forUser.toLowerCase()
                );
                if (found)
                    return setFormError(
                        createShareAlreadyExistsText(found.forUser, found.permission)
                    );
            }
            return createShareMutation({
                variables: {
                    entityId,
                    entityType,
                    forUser,
                    permission,
                },
            });
        },
        [createShareMutation, entityType, entityId, shares]
    );

    const updateShare = useCallback(
        (share) => {
            updateShareMutation({
                variables: {
                    entityId: share.entityId,
                    entityType: share.entityType,
                    forUser: share.forUser,
                    permission: share.permission,
                },
            });
        },
        [updateShareMutation]
    );

    const deleteShare = useCallback(
        (share) => {
            deleteShareMutation({
                variables: {
                    entityId: share.entityId,
                    entityType: share.entityType,
                    forUser: share.forUser,
                },
            });
        },
        [deleteShareMutation]
    );

    const runSharesUpdateQueue = useCallback(() => {
        pendingUpdates.map((share) => updateShare(share));
    }, [pendingUpdates, updateShare]);

    return {
        shares,
        entityName,
        error,
        createShare,
        deleteShare,
        tableHeader,
        enableCreateMode,
        disableCreateMode,
        createMode,
        formError,
        pendingUpdates,
        setPendingUpdates,
        runSharesUpdateQueue,
    };
};

const tableHeaderClassName = 'px-6 py-3';

export const createPartialPageTitle = (entityType) => {
    const entityTypeTranslation = ENTITY_TYPE_TRANSLATIONS[entityType];
    return upperFirst(interpolate(gettext('SHARING_PAGE_TITLE'), [entityTypeTranslation]));
};

const createPageTitle = (entityType, entityName) => {
    const partialPageTitle = createPartialPageTitle(entityType);

    if (entityName === loading) {
        return (
            <>
                {partialPageTitle}
                <span className="pl-1.5">
                    <LoadingSpinner className="w-3" />
                </span>
            </>
        );
    }

    return entityName ? `${partialPageTitle} ${entityName}` : partialPageTitle;
};

export default function GenericSharingPage({ entityType }) {
    const control = useQueryGenerator(entityType);
    const {
        shares,
        entityName,
        error,
        createShare,
        deleteShare,
        tableHeader,
        createMode,
        enableCreateMode,
        disableCreateMode,
        formError,
        pendingUpdates,
        setPendingUpdates,
        runSharesUpdateQueue,
    } = control;
    const inputRef = useRef();
    const permissionRef = useRef();
    const availablePermissions = useMemo(
        () => (entityType === ENTITY_TYPE.USER ? [PERMISSION.VIEW] : Object.keys(PERMISSION)),
        [entityType]
    );

    const preventUnsavedUpdates = useCallback(
        (e) => {
            if (!pendingUpdates.length) return;
            e.preventDefault();
            e.returnValue = '';
        },
        [pendingUpdates]
    );

    useEventListener('beforeunload', preventUnsavedUpdates);

    if (error) {
        return getGraphQLErrorExtensionCode(error) === ENTITY_UNKNOWN_ERROR_CODE ? (
            <NotFoundPage />
        ) : (
            <ErrorPage />
        );
    }

    return (
        <BasePage
            title={createPageTitle(entityType, entityName)}
            besideTitle={
                <>
                    {!!pendingUpdates.length && (
                        <Button
                            aria-label={gettext('UPDATE')}
                            className="mr-2"
                            onClick={runSharesUpdateQueue}
                        >
                            <FontAwesomeIcon icon={faUserPen} size="xl" className="mr-1.5" />
                            {gettext('UPDATE')} ({pendingUpdates.length})
                        </Button>
                    )}
                    <Button aria-label={gettext('ADD_SHARE')} onClick={() => enableCreateMode()}>
                        <FontAwesomeIcon icon={faCirclePlus} size="xl" className="mr-1.5" />
                        {gettext('ADD_SHARE')}
                    </Button>
                </>
            }
        >
            <div className="main-container-new">
                <p>
                    {interpolate(gettext('SHARING_PAGE_BODY'), [
                        ENTITY_TYPE_TRANSLATIONS[entityType],
                    ])}
                </p>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        createShare(
                            inputRef.current.value,
                            availablePermissions.length === 1
                                ? availablePermissions[0]
                                : permissionRef.current.value
                        );
                    }}
                >
                    <table className="my-3 w-full text-left text-gray-900 shadow-md">
                        <thead>
                            <tr>
                                <th className={tableHeaderClassName}>
                                    {gettext('Email address or username')}
                                </th>
                                {tableHeader.map((columnName) => (
                                    <th className={tableHeaderClassName} key={columnName}>
                                        {columnName}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="text-sm">
                            {createMode && (
                                <CreateRow
                                    availablePermissions={availablePermissions}
                                    handleCancelClick={() => disableCreateMode()}
                                    inputRef={inputRef}
                                    permissionRef={permissionRef}
                                    error={formError}
                                />
                            )}
                            {shares.map((share) => (
                                <EditRow
                                    availablePermissions={availablePermissions}
                                    pendingUpdates={pendingUpdates}
                                    setPendingUpdates={setPendingUpdates}
                                    key={getClient().cache.identify(share)}
                                    share={share}
                                    deleteShare={deleteShare}
                                />
                            ))}
                        </tbody>
                    </table>
                </form>
            </div>
        </BasePage>
    );
}

GenericSharingPage.propTypes = {
    entityType: PropTypes.string.isRequired,
};
