import { useContext, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { isEqual } from 'lodash-es';
import { defaultPropTypes, FormContext, useIsOverridable, useIsOverridden } from '../utils';
import FormFieldContainer from './FormFieldContainer';
import Tooltip from '../../../utils/tooltip';
import Select from '../../form/Select';
import { useConfigHelperUpdated } from '../../pages/measuringPoint/configHelper';
import { tryToBoolean } from '../../../utils/cast';

const useFilterHiddenFieldsByConfigHelper = (name, incommingOptions) => {
    const [options, setOptions] = useState(incommingOptions);

    useConfigHelperUpdated(
        name,
        // On config helper and availableFields update:
        (availableFields) => {
            if (!(name in availableFields)) {
                return;
            }

            setOptions((oldOptions) => {
                const newOptions = incommingOptions.filter(([key, _value]) =>
                    availableFields?.[name].includes(key)
                );

                return isEqual(oldOptions, newOptions) ? oldOptions : newOptions;
            });
        },
        // On config helper disabled:
        () => {
            setOptions(() => incommingOptions);
        }
    );

    return options;
};

function ChoiceField({ fieldOptions, extraChoiceOptions = [] }) {
    const { name, settings, required } = fieldOptions;

    const { control } = useFormContext();

    const PROJECT_OPTION = 'PROJECT_OPTION';

    const { parentValues, setOverriddenField } = useContext(FormContext);

    const parentValue = parentValues?.[name];
    const overridable = useIsOverridable(name);
    const overridden = useIsOverridden(name);

    const optionsArray = useMemo(() => Array.from(settings.options), [settings.options]);

    const originalOptions = useFilterHiddenFieldsByConfigHelper(name, optionsArray);

    const projectOptions = useMemo(() => {
        if (!overridable) {
            return [];
        }

        const translation = settings.options.get(parentValue);
        return [[PROJECT_OPTION, `${translation} - ${gettext('project setting')}`]];
    }, [overridable, parentValue, settings.options]);

    const options = useMemo(
        () => [...originalOptions, ...extraChoiceOptions, ...projectOptions],
        [extraChoiceOptions, originalOptions, projectOptions]
    );

    const { field } = useController({
        name,
        control,
        rules: { required },
        defaultValue: settings.default,
    });

    function transformInput(value) {
        if (overridable && !overridden) {
            return PROJECT_OPTION;
        }

        return value ?? '';
    }

    function transformOutput(e) {
        const { value: rawValue } = e.target;
        const value = tryToBoolean(rawValue);

        if (overridable) {
            if (value === PROJECT_OPTION && overridden) {
                setOverriddenField(name, false);
                return parentValue;
            }

            if (!overridden) {
                setOverriddenField(name, true, true);
            }
        }

        // Normal behaviour.
        return value;
    }

    return (
        <FormFieldContainer fieldOptions={fieldOptions} showOverrideCheckbox={false}>
            <Tooltip
                content={gettext('Note: changing this value will override the project settings.')}
                disabled={!overridable || overridden}
            >
                <Select
                    id={`id_${name}`}
                    placeholder={settings.placeholder}
                    onChange={(e) => field.onChange(transformOutput(e))}
                    value={transformInput(field.value)}
                    data={options}
                />
            </Tooltip>
        </FormFieldContainer>
    );
}
ChoiceField.propTypes = defaultPropTypes;

export default ChoiceField;
