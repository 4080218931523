import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { computePosition, shift } from '@floating-ui/core';
import { platform as platformDom } from '@floating-ui/dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import SelectionBlock from '../selectionBlock/SelectionBlock';

// The value '160' is gotten arbitrarily after manual testing,
// to determine which point is best to show the minimal progress bar
// and enlarged selection block on hover.
const minimalSelectionWidth = 160;

const ActionIcon = ({ icon, onClick, title, className }) => (
    <div onClick={onClick}>
        <FontAwesomeIcon
            icon={icon}
            className={`absolute top-1.5 cursor-pointer text-hc-dark-grey ${className}`}
            size="lg"
            title={title}
        />
    </div>
);
ActionIcon.propTypes = {
    icon: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
};

function SelectionWrapper({
    block,
    formatTime,
    unit,
    id,
    buildMode,
    setShowModal,
    setCurrentBlock,
    deleteBlock,
}) {
    const { period, width, left } = block;
    const { timeWindow, timeSpan, value } = period;
    const isMinimal = width < minimalSelectionWidth;
    const elementRef = useRef();
    const [isHovered, setIsHovered] = useState(false);
    const [overriddenLeft, setOverriddenLeft] = useState();

    useEffect(() => {
        if (!(isHovered && isMinimal)) {
            setOverriddenLeft(null);
            return;
        }

        const element = elementRef.current;
        const parent = element.parentElement;

        const canvas = {
            x: 0,
            y: 0,
            ...platformDom.getDimensions(parent),
        };

        // The `reference` contains initial width and left
        // position of the block.
        const reference = {
            width,
            height: 0,
            x: left,
            y: 0,
        };

        // `floating` is the Selection Block.
        const floating = {
            x: 0,
            y: 0,
            ...platformDom.getDimensions(element),
        };

        const platform = {
            getElementRects: (data) => data,
            getDimensions: platformDom.getDimensions,
            getClippingRect: () => canvas,
        };

        computePosition(reference, floating, {
            platform,
            middleware: [shift()],
        }).then(({ x }) => {
            setOverriddenLeft(x);
        });
    }, [isHovered, block, isMinimal, width, left]);

    return (
        <div
            className="absolute hover:z-10"
            style={{
                left: overriddenLeft || block.left,
                width: isMinimal && isHovered ? 270 : block.width,
            }}
            onMouseOver={() => (buildMode ? {} : setIsHovered(true))}
            onMouseLeave={() => setIsHovered(false)}
            data-testid={id}
            ref={elementRef}
        >
            {buildMode && (
                <div className="flex">
                    <ActionIcon
                        icon={faPenToSquare}
                        onClick={() => {
                            setCurrentBlock(block?.period.timeWindow);
                            setShowModal(true);
                        }}
                        title={gettext('EDIT')}
                        className="right-2"
                    />
                    <ActionIcon
                        icon={faTrash}
                        onClick={() => {
                            deleteBlock(block?.period.timeWindow);
                        }}
                        title={gettext('DELETE')}
                        className="right-6"
                    />
                </div>
            )}
            <SelectionBlock
                {...{
                    name: timeWindow.name,
                    startTime: formatTime(timeSpan.from),
                    endTime: formatTime(timeSpan.to),
                    warningLevel: timeWindow.warningLevel,
                    value,
                    alarmLevel: timeWindow.alarmLevel,
                    correction: timeWindow.correction,
                    isMinimal: isMinimal && !isHovered,
                    unit,
                }}
            />
        </div>
    );
}

SelectionWrapper.propTypes = {
    block: PropTypes.object.isRequired,
    formatTime: PropTypes.func.isRequired,
    unit: PropTypes.string,
    id: PropTypes.string.isRequired,
    buildMode: PropTypes.bool,
    changeTemplate: PropTypes.func,
    setShowModal: PropTypes.func,
    setCurrentBlock: PropTypes.func,
    deleteBlock: PropTypes.func,
};

export default SelectionWrapper;
