import { useTranslation } from 'react-i18next';
import { BasePage } from './BasePage';

export function NotFoundPage() {
    const { t } = useTranslation();
    const title = t('Page not found');
    return (
        <BasePage title={title}>
            <div className="main-container-new pb-4">{title}</div>
        </BasePage>
    );
}
