import { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';

import { useParams } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { BasePage } from '../BasePage';
import Table from '../../table/Table';
import { format, getBrowserTimezone } from '../../../utils/formatting';
import { getSwarmInfoById } from '../measuringPoint/utils';
import useOrderAndPaginationControl from '../../table/hooks';
import useSoundClipQueryGenerator from './utils';
import { LoadingPage } from '../LoadingPage';
import Pagination from '../../table/Pagination';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';

const loadingState = Symbol('loading');

const pageSize = 10;

function SoundClipsPage() {
    const { showBoundary } = useErrorBoundary();
    const { id, startTime, endTime } = useParams();
    const orderAndPaginationControl = useOrderAndPaginationControl(pageSize);
    const [measuringPointName, setMeasuringPointName] = useState(loadingState);
    const [timezone, setTimezone] = useState(getBrowserTimezone());
    const { soundClips, loading, updating } = useSoundClipQueryGenerator(
        orderAndPaginationControl,
        id,
        startTime,
        endTime
    );
    const title = useMemo(() => {
        const [startTimeHuman, endTimeHuman] = [startTime, endTime].map((timestamp) =>
            format(new Date(parseInt(timestamp, 10)), { timezone })
        );

        return interpolate(
            gettext('SOUND_CLIPS_PAGE_TITLE'),
            { measuringPointName, startTime: startTimeHuman, endTime: endTimeHuman },
            true
        );
    }, [measuringPointName, startTime, endTime, timezone]);

    const columns = useMemo(
        () => [
            {
                key: 'startTime',
                title: gettext('START_TIME'),
                showSortIcon: true,
                render(column, row) {
                    return format(new Date(row.startTime), { timezone });
                },
            },
            {
                key: 'endTime',
                title: gettext('END_TIME'),
                showSortIcon: true,
                render(column, row) {
                    return format(new Date(row.endTime), { timezone });
                },
            },

            {
                key: 'waveFile',
                title: (
                    <span className="space-x-2">
                        {updating && <LoadingSpinner className="h-5 align-middle text-gray-400" />}
                        {orderAndPaginationControl.totalCount > pageSize && (
                            <Pagination orderAndPaginationControl={orderAndPaginationControl} />
                        )}
                    </span>
                ),
                render(column, row) {
                    return (
                        <div className="flex justify-end gap-4">
                            <audio controls className="h-8 w-72">
                                <source src={row.streamUrl} type="audio/wav" />
                                {gettext('AUDIO_PLAYER_NOT_SUPPORTED')}
                            </audio>
                            <a href={row.downloadUrl} download className="hover:text-primary">
                                <FontAwesomeIcon
                                    icon={faCloudArrowDown}
                                    className="h-8 w-8 fill-current"
                                />
                            </a>
                        </div>
                    );
                },
            },
        ],
        [timezone, updating, orderAndPaginationControl]
    );

    useEffect(() => {
        // Let the UI know we are querying.
        setMeasuringPointName(loadingState);

        getSwarmInfoById(id, false)
            .then((result) => {
                setMeasuringPointName(result.name);
                setTimezone(result.timezone.replace('_', '/'));
            })
            .catch((error) => {
                showBoundary(error);
            });
    }, [id, setMeasuringPointName, showBoundary]);

    if (measuringPointName === loadingState) {
        return <LoadingPage />;
    }

    return (
        <BasePage title={title} besideTitle={`${gettext('TIMEZONE')}: ${timezone}`}>
            <div className="main-container-new pb-2">
                <Table
                    data={soundClips.map((edge) => edge.node)}
                    columns={columns}
                    loading={loading}
                    orderAndPaginationControl={orderAndPaginationControl}
                />
            </div>
        </BasePage>
    );
}

export default SoundClipsPage;
