import { useMemo, useEffect, useState, useCallback } from 'react';
import { isEmpty, omit } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { useObservableState, useSubscription } from 'observable-hooks';
import { nextMonday } from 'date-fns';
import { ErrorBoundary } from 'react-error-boundary';

import { defaultPropTypes, useWatchObservable } from '../utils';
import schema from '../../../schema.json';
import LdenCalendar from '../../ldenCalendar/LdenCalendar';
import { getUnitWithFrequencyWeighting } from '../../../graph/graph-elements/sound-plugin/unit-formatter';
import {
    extendTemplate,
    formatTemplateData,
    templateStartInUtc,
    getFullDateFromNumber,
} from '../../ldenCalendar/utils';
import ErrorContainer from '../../errorContainer/ErrorContainer';
import { applyModifierForTimezone } from '../../ldenCalendar/weekPicker/WeekPicker';

const utcTimezone = 'Etc/UTC';
const inViewStartDate = applyModifierForTimezone(templateStartInUtc, utcTimezone, nextMonday);

function LdenGuidelinePreviewField() {
    const { watch, setValue } = useFormContext();
    const [customTemplate, setCustomTemplate] = useState([]);
    const [previousGuideline, setPreviousGuideline] = useState(null);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const ldenGuideline$ = useWatchObservable(watch, 'ldenGuidelineChoiceType');
    const ldenGuideline = useObservableState(ldenGuideline$);

    const ldenSettings$ = useWatchObservable(watch, 'ldenMeasurementSettings');
    const ldenSettings = useObservableState(ldenSettings$);

    const frequencyWeighting$ = useWatchObservable(watch, 'frequencyWeighting');
    const frequencyWeighting = useObservableState(frequencyWeighting$);

    const currentDate = useMemo(() => new Date(), []);
    const isCustom = useMemo(() => ldenGuideline === 'LdenGuidelineCustom', [ldenGuideline]);

    const deleteCustomTemplate = useCallback(() => {
        setCustomTemplate([]);
        setValue('ldenMeasurementSettings', []);
    }, [setValue]);

    useSubscription(useWatchObservable(watch, 'override.ldenGuidelineChoiceType'), {
        next: (value) => {
            if (value !== undefined) {
                setValue('override.ldenMeasurementSettings', value);
            }
        },
    });

    useEffect(() => {
        if (!initialLoadComplete && ldenGuideline) {
            if (isCustom) {
                const updatedSettings = !isEmpty(ldenSettings)
                    ? ldenSettings.map((setting) => ({
                          ...setting,
                          startDate: getFullDateFromNumber(setting.startDay, setting.startTime),
                          endDate: getFullDateFromNumber(setting.endDay, setting.endTime),
                      }))
                    : [];
                setCustomTemplate(updatedSettings);
            }
            setInitialLoadComplete(true);
            setPreviousGuideline(ldenGuideline);
        } else if (initialLoadComplete && ldenGuideline !== previousGuideline) {
            // Handle guideline changes after initial load.
            if (isCustom) {
                setValue('ldenMeasurementSettings', []);
            }
            setPreviousGuideline(ldenGuideline);
        }
    }, [
        ldenGuideline,
        isCustom,
        ldenSettings,
        customTemplate,
        initialLoadComplete,
        previousGuideline,
        setValue,
    ]);

    const unit = useMemo(() => {
        const config = frequencyWeighting
            ? {
                  frequency_weighting: frequencyWeighting,
              }
            : {};

        return getUnitWithFrequencyWeighting(config);
    }, [frequencyWeighting]);

    const templateToUse = useMemo(
        () => (isCustom && isEmpty(customTemplate) ? [] : customTemplate),
        [isCustom, customTemplate]
    );

    const template = useMemo(
        () =>
            ldenGuideline
                ? extendTemplate(isCustom ? templateToUse : schema.ldenGuidelines[ldenGuideline], 3)
                : [],
        [ldenGuideline, isCustom, templateToUse]
    );

    useEffect(() => {
        if (isCustom && !isEmpty(customTemplate)) {
            setValue(
                'ldenMeasurementSettings',
                templateToUse?.map((window) => omit(window, ['startDate', 'endDate']))
            );
        }
    }, [template, setValue, isCustom, customTemplate, templateToUse]);

    const data = useMemo(() => formatTemplateData(template), [template]);

    if (isEmpty(template) && !isCustom) {
        return null;
    }

    return (
        <ErrorBoundary fallback={<ErrorContainer />}>
            <LdenCalendar
                data={data}
                buildMode={isCustom}
                // By extending the template for 3 weeks and setting the view start date
                // to the second week, we ensure that any time windows starting before or
                // ending after the initial week are covered. This prevents empty spots at
                // the beginning and end of the displayed week.
                inViewStartDate={inViewStartDate}
                dayStartOffset={7}
                timezone={utcTimezone}
                unit={unit}
                currentDate={currentDate}
                changeTemplate={setCustomTemplate}
                customTemplate={customTemplate}
                deleteCustomTemplate={deleteCustomTemplate}
            />
        </ErrorBoundary>
    );
}
LdenGuidelinePreviewField.propTypes = defaultPropTypes;

export default LdenGuidelinePreviewField;
