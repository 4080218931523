import { useEffect, useMemo, useRef } from 'react';
import { cloneDeep, isEmpty, isEqual, last, set } from 'lodash-es';
import { BehaviorSubject, distinctUntilChanged, filter, map, ReplaySubject } from 'rxjs';
import { useObservableState, useSubscription } from 'observable-hooks';
import warning from './logger';

export const PLACEHOLDER_ROW_STRATEGY = Object.freeze({
    ADD_WHEN_EMPTY: 1,
    ADD_WHEN_LAST_ROW_IS_DIRTY: 2,
});

function unknownPlaceholderStrategy(strategy) {
    warning(`RowManager cannot handle placeholder strategy: ${strategy}.`);
}

export class RowManager {
    maxRows;

    emptyRow;

    constructor(config) {
        this.storeConfig(config);

        // Rows that are coming from the API.
        this.input$ = new BehaviorSubject([]);

        // Rows that are going back to the API.
        this.output$ = new ReplaySubject(1);

        // The rows that will be shown in the UI.
        this.rows$ = new BehaviorSubject([]);

        this.rowLimitReached$ = this.rows$.pipe(
            map((rows) => rows.length >= this.maxRows),
            distinctUntilChanged()
        );

        this.showDeleteButtons$ = this.rows$.pipe(
            map((rows) => rows.length > 1),
            distinctUntilChanged()
        );

        // Handle incoming rows.
        this.handleInputRows(this.input$).subscribe(this.rows$);

        // Handle outgoing rows.
        this.handleOutputRows(this.rows$).subscribe(this.output$);
    }

    storeConfig(config) {
        this.maxRows = config.maxRows;
        this.emptyRow = config.emptyRow;
        this.placeholderRowStrategy =
            config.placeholderRowStrategy ?? PLACEHOLDER_ROW_STRATEGY.ADD_WHEN_EMPTY;
    }

    // Method to handle incoming rows.
    handleInputRows(input$) {
        return input$.pipe(map((input) => this.addPlaceholderRow(input)));
    }

    // Method to handle outgoing rows.
    handleOutputRows(rows$) {
        return rows$.pipe(
            // Optionally strip the placeholder row that was added in `handleInputRows`.
            map((output) => this.stripPlaceholderRow(output)),
            // There is no need to emit output when the output is the same a the input.
            filter((output) => !isEqual(output, this.input$.getValue()))
        );
    }

    createRow() {
        return this.emptyRow;
    }

    addPlaceholderRow(rows) {
        let shouldAddPlaceholderRow = false;

        switch (this.placeholderRowStrategy) {
            case PLACEHOLDER_ROW_STRATEGY.ADD_WHEN_EMPTY:
                shouldAddPlaceholderRow = isEmpty(rows);
                break;
            case PLACEHOLDER_ROW_STRATEGY.ADD_WHEN_LAST_ROW_IS_DIRTY:
                // Add a placeholder row when the last row in rows is not an
                // empty row. In other words, the last row is dirty.
                shouldAddPlaceholderRow = !isEqual(last(rows), this.emptyRow);
                break;
            default:
                unknownPlaceholderStrategy(this.placeholderRowStrategy);
        }

        return shouldAddPlaceholderRow ? [...rows, this.createRow()] : rows;
    }

    isEmptyRow(row) {
        return isEqual(row, this.emptyRow);
    }

    stripPlaceholderRow(rows) {
        switch (this.placeholderRowStrategy) {
            case PLACEHOLDER_ROW_STRATEGY.ADD_WHEN_EMPTY:
                if (rows.length === 1 && isEqual(rows[0], this.emptyRow)) {
                    return [];
                }
                break;
            case PLACEHOLDER_ROW_STRATEGY.ADD_WHEN_LAST_ROW_IS_DIRTY:
                return rows.filter((row) => !this.isEmptyRow(row));
            default:
                unknownPlaceholderStrategy(this.placeholderRowStrategy);
        }

        return rows;
    }

    // Add an empty row to the list.
    addRow() {
        const current = this.rows$.getValue();
        this.rows$.next([...current, this.createRow(current)]);
    }

    // Remove a row from the list.
    removeRow(index) {
        const current = this.rows$.value;
        this.rows$.next(current.filter((row, i) => i !== index));
    }

    /**
     * Changes a specific row in the list.
     *
     * @param {number} index - The index of the row to change.
     * @param {string|object} keyOrUpdateObject -
     *        If a string, it represents the key to update in the row.
     *        If an object, it represents an object containing key-value pairs to update.
     * @param {any} [newValue] - The new value to set at the specified key.
     */
    changeRow(index, keyOrUpdateObject, newValue) {
        const current = this.rows$.getValue();
        const copy = [...current];

        copy[index] = cloneDeep(current[index]);

        if (typeof keyOrUpdateObject === 'string') {
            // Set the new value at the specified path on the row.
            set(copy[index], keyOrUpdateObject, newValue);
        } else if (typeof keyOrUpdateObject === 'object' && keyOrUpdateObject !== null) {
            // Merge the updateObject onto the row.
            Object.assign(copy[index], keyOrUpdateObject);
        } else {
            unknownPlaceholderStrategy(this.placeholderRowStrategy);
        }

        this.rows$.next(copy);
    }

    // Method to integrate this class with React components.
    static useRowManager(value, onChange, maxRows, emptyRow, config) {
        // ESlint will complain about that hooks cannot be called in
        // a class component, but we are not doing that.
        /* eslint-disable react-hooks/rules-of-hooks */
        const emptyRowRef = useRef(emptyRow);
        const configRef = useRef(config);
        const manager = useMemo(
            () =>
                new this({
                    maxRows,
                    emptyRow: emptyRowRef.current,
                    ...configRef.current,
                }),
            [maxRows, emptyRowRef, configRef]
        );

        // Push the incoming values to the RowManager.
        useEffect(() => {
            manager.input$.next(value);
        }, [manager, value]);

        // Push the outgoing values from RowManager to the onChange callback.
        useSubscription(manager.output$, onChange);

        const rows = useObservableState(manager.rows$);
        const rowLimitReached = useObservableState(manager.rowLimitReached$);
        const showDeleteButtons = useObservableState(manager.showDeleteButtons$);

        return { rows, manager, rowLimitReached, showDeleteButtons };
        /* eslint-enable */
    }
}
