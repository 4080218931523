import { useContext, useEffect } from 'react';
import {
    BehaviorSubject,
    EMPTY,
    Subject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    of,
    startWith,
    switchMap,
} from 'rxjs';
import { useSubscription, useObservable, useObservableEagerState } from 'observable-hooks';
import { first, isArray, isEmpty, isFunction, isString } from 'lodash-es';

import schema from '../../../schema.json';
import { featureCheckSwarm$, featureCheckProject$ } from './featureCheck';
import warning from '../../../utils/logger';

import { FormContext } from '../../reactForm/utils';
import useSubscriptionWithSkip from '../../../utils/use-subscription';
import { tryToBoolean } from '../../../utils/cast';
import { SwarmType } from '../../../enums';
import { shareReplayRefCount } from '../../../utils/rxjs';

export const ANY = '__any__';

const hashFieldOption = (field, option) => [field, option].join();

const getAvailableFieldsAndOptions = (
    fields,
    disabledFields,
    values,
    setValue,
    availableFields = {}
) => {
    // All fields considered disabled until proven otherwise.
    fields.forEach((options, field) => {
        const canBeAdded = !disabledFields.includes(hashFieldOption(field, ANY));
        const optionKeys = Array.from(options.keys());

        // Fields without specific options need to be treated differently.
        if (optionKeys.includes(ANY)) {
            // Make sure this field is not disabled before we enable it.
            if (canBeAdded) {
                availableFields[field] = ANY;
            }
            // No way to traverse down any fields.
            return;
        }

        const availableOptions = optionKeys.filter(
            (option) => !disabledFields.includes(hashFieldOption(field, option))
        );

        // Prevent it from getting disabled later.
        if (canBeAdded) {
            availableFields[field] = availableOptions;
        }

        let currentOption = values[field];

        if (!availableOptions.includes(currentOption)) {
            // Our current option is not available anymore, change it.
            // eslint-disable-next-line prefer-destructuring
            currentOption = availableOptions[0];
            setValue(field, currentOption);
        }

        // Iterate down the current option.
        getAvailableFieldsAndOptions(
            options.get(currentOption),
            disabledFields,
            values,
            setValue,
            availableFields
        );
    });

    return availableFields;
};

// Here we convert the config helper choices table from recursive objects into recursive Map's.
// This is necessary because objects in JS cannot contain booleans as keys, while those of Map's
// can. If we were to use objects, the boolean choices for boolean fields would be cast in strings.
// If a form later sends an update of a boolean field to the configuration helper, the value send
// will be a true boolean. If we then had to look up the choice in the configuration helper, we
// would have to convert the real boolean into a string boolean to make the comparison. Then, once
// we've found the option and the configuration helper has decided that a different choice should be
// selected for that field, we'll need to convert the boolean string back to a true boolean for the
// form to use again. All in all, it is therefore easier to do all these conversions in advance and
// then save them in a Map.
function convertConfigFieldsIntoMap(configFields) {
    return new Map(
        Object.entries(configFields).map(([field, options]) => [
            tryToBoolean(field),
            convertConfigFieldsIntoMap(options),
        ])
    );
}

export class ConfigHelper {
    constructor(...args) {
        this.init(...args);
    }

    init(swarmType = SwarmType.VIBRATION) {
        // Currently selected values that are used to determine the available fields.
        this.swarmType = swarmType;
        this.values$ = new Subject();
        this.availableFields$ = new BehaviorSubject({});
        this.currentlySelectedOptions$ = new BehaviorSubject({});
        this.allFields = schema.configHelper.allFields;
        this.configFields = this.transformConfigFields(schema.configHelper.configFields);
        this.enabled = true;
        this.deviceName$ = this.createDeviceName$();
        // Adding `shareReplayRefCount` as a cache here because the emitted values
        // are used by the feature disabling, device name corrector, and the disabled
        // features banner.
        this.swarmFeatures$ = this.createSwarmFeatures$().pipe(shareReplayRefCount());
        this.disabledFeatures$ = this.createDisabledFeatures$();
        this.deviceNameCorrection$ = this.createDeviceNameCorrection$();

        combineLatest(this.values$, this.disabledFeatures$).subscribe(
            ([values, disabledFields]) => {
                const currentlySelectedOptions = {};

                function setFieldValue(field, value) {
                    currentlySelectedOptions[field] = value;
                }

                const availableFields = getAvailableFieldsAndOptions(
                    this.configFields,
                    disabledFields,
                    values,
                    setFieldValue
                );

                this.availableFields$.next(availableFields);
                this.currentlySelectedOptions$.next(currentlySelectedOptions);
            }
        );
    }

    // Performs the Map conversion, but also allows extension by inheriting classes to
    // make other changes to the configuration fields.
    transformConfigFields(configFields) {
        const swarmTypeSpecificConfigFields = configFields.swarmType[this.swarmType];

        if (!swarmTypeSpecificConfigFields) {
            throw new Error(`Config helper has no support for SWARM type '${this.swarmType}'.`);
        }

        return convertConfigFieldsIntoMap(
            // Grab the right set of fields for this SWARM type.
            configFields.swarmType[this.swarmType]
        );
    }

    updateValues(values) {
        this.values$.next(values);
    }

    createDeviceName$() {
        return this.values$.pipe(
            map((values) => {
                const { deviceName } = values;
                return deviceName && deviceName.length === 6 ? deviceName : null;
            }),
            distinctUntilChanged(),
            shareReplayRefCount()
        );
    }

    createSwarmFeatures$() {
        return this.deviceName$.pipe(
            switchMap((deviceName) => {
                if (!deviceName) {
                    return of([]);
                }

                return featureCheckSwarm$(deviceName).pipe(
                    catchError((error) => {
                        warning(error);
                        return of([]);
                    }),
                    startWith([])
                );
            })
        );
    }

    createDisabledFeatures$() {
        return this.swarmFeatures$.pipe(
            // Returns an array like:
            // [
            //     'guideLine,SS_025211',
            //     'guideLine,SS_4604861',
            // ]
            map((swarmFeatures) => {
                if (isEmpty(swarmFeatures)) {
                    return Object.entries(
                        schema.configHelper.neverAllowedFeatures[this.swarmType]
                    ).map(([field, option]) => hashFieldOption(field, option));
                }

                // Loop over all the devices.
                return swarmFeatures.flatMap(({ unavailableFeatures }) =>
                    // Map all the unavailableFeatures of all devices into one big list.
                    unavailableFeatures.map(({ field, option }) => hashFieldOption(field, option))
                );
            })
        );
    }

    // This function creates an observable that emits the corrected device name.
    // It achieves this by combining the response from the SWARM features request
    // and the device name entered into the form. If the backend sanitizes the
    // device name, the observable will emit the corrected name.
    createDeviceNameCorrection$() {
        return combineLatest([this.deviceName$, this.swarmFeatures$]).pipe(
            // Extract the device name from the SWARM features data.
            map(([deviceName, swarmFeatures]) => [deviceName, first(swarmFeatures)?.sensorName]),
            // Only pass emissions where both names are strings and they do not match.
            filter(
                ([deviceName, correctedName]) =>
                    isString(deviceName) && isString(correctedName) && deviceName !== correctedName
            ),
            // Finally, only emit the corrected device name.
            map(([_deviceName, correctedName]) => correctedName)
        );
    }
}

export class ProjectConfigHelper extends ConfigHelper {
    init(swarmType, projectId) {
        this.projectId = projectId;

        super.init(swarmType);
    }

    createSwarmFeatures$() {
        if (!this.projectId) {
            return of([]);
        }

        return featureCheckProject$(this.projectId).pipe(
            catchError((error) => {
                warning(error);
                return of([]);
            })
        );
    }
}

export class DisabledConfigHelper extends ConfigHelper {
    constructor() {
        super();
        this.enabled = false;
    }

    updateValues() {}

    createSwarmFeatures$() {
        return EMPTY;
    }
}

export const useConfigHelperWithReactFormHook = (configHelper, reactHookForm) => {
    const { watch, setValue, getValues } = reactHookForm;

    // Start sending the form updates to the config helper.
    useEffect(() => {
        if (!configHelper.enabled) {
            return null;
        }

        // Send the current values to the config helper.
        configHelper.updateValues(getValues());

        // Start watching for updates and send them to the configHelper too.
        const subscription = watch((values) => {
            configHelper.updateValues(values);
        });

        return () => {
            subscription.unsubscribe();

            // Reset the config helper.
            configHelper.updateValues({});
        };
    }, [configHelper, getValues, watch, setValue]);

    // Currently selected options may not be available anymore after running the
    // config helper. Here we start by listening to configuration helper's new best
    // recommendations for selected fields and applying them to the form.
    useSubscription(configHelper.currentlySelectedOptions$, (currentlySelectedOptions) => {
        Object.entries(currentlySelectedOptions).forEach(([field, value]) => {
            setValue(field, value);
        });
    });

    return configHelper;
};

// onUpdate callback will be called when the configHelper is enabled and the
// given fieldName is part of the config helper fields. onUpdate will be called
// with the new available fields as first argument.
export const useConfigHelperUpdated = (fieldName, onUpdate, onDisabled) => {
    const { configHelper } = useContext(FormContext);

    const enabled =
        configHelper.enabled &&
        // And check if this fieldName is part of the config helper fields.
        // `fieldName` could also be an array of field names.
        (isArray(fieldName) ? fieldName : [fieldName]).some((name) =>
            configHelper.allFields.includes(name)
        );

    // Watch enabled state and dispatch onDisabled callback if needed.
    useEffect(() => !enabled && isFunction(onDisabled) && onDisabled(), [enabled, onDisabled]);

    // When enabled, start watching the available fields observable
    // and dispatch callback when needed.
    useSubscriptionWithSkip(configHelper.availableFields$, onUpdate, !enabled);
};

export const useHiddenByConfigHelper = (name) => {
    const { configHelper } = useContext(FormContext);

    const hidden$ = useObservable(
        (input$) =>
            input$.pipe(
                switchMap(([n, c]) => {
                    const enabled = c.enabled && c.allFields.includes(n);

                    // If the config helper is turned off or the field is not part of the
                    // config helper, the field is visible by default.
                    if (!enabled) {
                        return of(false);
                    }

                    // If the field is not available inside `availableFields`, then it should
                    // be hidden.
                    return c.availableFields$.pipe(
                        map((availableFields) => !(n in availableFields)),
                        distinctUntilChanged()
                    );
                })
            ),
        [name, configHelper]
    );

    return useObservableEagerState(hidden$);
};
