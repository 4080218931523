import { useQuery, gql, useMutation, NetworkStatus } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DOWNLOAD_STATUS } from '../../enums';
import { updateReadyReportsCount } from '../../utils';
import { createAfterCursor } from '../../table/utils';
import { presignedURLRefreshInterval } from '../../../constants';

const GRAPH_REPORTS_FRAGMENT = gql`
    fragment GraphReportsTypeFields on GraphReportType {
        id
        url
        name
        lastDownloaded
        status
        size
        category
    }
`;

const GRAPH_REPORTS = gql`
    query graphReports(
        $orderBy: String
        $before: String
        $after: String
        $first: Int
        $last: Int
        $ids: [ID]
    ) {
        graphReports(
            orderBy: $orderBy
            before: $before
            after: $after
            first: $first
            last: $last
            ids: $ids
        ) {
            readyReportsCount
            totalCount
            edges {
                node {
                    ...GraphReportsTypeFields
                }
            }
            pageInfo {
                startCursor
                endCursor
            }
        }
    }
    ${GRAPH_REPORTS_FRAGMENT}
`;

const UPDATE_LAST_DOWNLOAD = gql`
    mutation updateGraphReportLastDownloaded($id: ID!) {
        updateGraphReportLastDownloaded(id: $id) {
            graphReport {
                ...GraphReportsTypeFields
            }
        }
    }
    ${GRAPH_REPORTS_FRAGMENT}
`;

const createQueryVariables = ({ currentPage, pageSize, orderBy }) => ({
    first: pageSize,
    after: createAfterCursor(currentPage, pageSize),
    orderBy,
});

// Poll for new presigned URLs almost every 1 hour.
const reportURLPollInterval = presignedURLRefreshInterval;
// Poll for new status every 5 seconds.
const reportStatusPollInterval = 5000;

export default function useGraphReportQueryGenerator(orderAndPaginationControl) {
    const [graphReports, setGraphReports] = useState([]);

    const queryVariables = useMemo(
        () => createQueryVariables(orderAndPaginationControl),
        [orderAndPaginationControl]
    );

    const isPolling = useRef(false);
    const pollInterval = useRef(reportStatusPollInterval);

    const { data, loading, startPolling, stopPolling, networkStatus, refetch } = useQuery(
        GRAPH_REPORTS,
        {
            variables: queryVariables,
            fetchPolicy: 'network-only',
        }
    );

    const [updateLastDownloadMutation] = useMutation(UPDATE_LAST_DOWNLOAD, {
        onCompleted: () => {
            refetch();
        },
    });

    useEffect(() => {
        if (data) {
            updateReadyReportsCount(data.graphReports?.readyReportsCount);
            setGraphReports(data.graphReports?.edges ?? []);
            orderAndPaginationControl.setTotalCount(data.graphReports?.totalCount);
        }
    }, [data, orderAndPaginationControl]);

    // Adapts polling interval based on report status: faster if reports are in progress.
    useEffect(() => {
        const shouldPollForReportStatus = graphReports.some(
            (edge) => edge.node.status === DOWNLOAD_STATUS.IN_PROGRESS
        );

        const newInterval = shouldPollForReportStatus
            ? reportStatusPollInterval
            : reportURLPollInterval;

        if (isPolling.current) {
            if (newInterval !== pollInterval.current) {
                stopPolling();
                startPolling(newInterval);
                pollInterval.current = newInterval;
            }
        } else {
            startPolling(newInterval);
            isPolling.current = true;
        }
        // Cleanup function.
        return () => {
            if (isPolling.current) {
                stopPolling();
            }
        };
    }, [graphReports, startPolling, stopPolling]);

    return {
        graphReports,
        updateLastDownloadMutation,
        loading: loading && networkStatus === NetworkStatus.loading,
        updating: loading && networkStatus === NetworkStatus.setVariables,
    };
}
