import Skeleton from 'react-loading-skeleton';
import { BasePage } from './BasePage';

export function LoadingPage() {
    return (
        <BasePage title={<Skeleton />}>
            <div className="main-container-new pb-4">
                <Skeleton count="10" />
            </div>
        </BasePage>
    );
}
