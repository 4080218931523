import { identity, isEqual, map as lodashMap, max } from 'lodash-es';

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { combineLatest, combineLatestWith, distinctUntilChanged, fromEvent, map, of } from 'rxjs';
import { useEffect, useRef } from 'react';
import { DataSet } from './graph/dataset';
import { ValueFrequencyGraph, ValueTimeGraph } from './graph/graph';
import { Scales, dataSetGuideLine, overrides$, setGraphLoadedFlag } from './graph/graph-helpers';
import VeffDayGraph from './graph/veff-day-graph';
import { distanceConverter } from './utils/formatting';
import { applyNamingConventionsByGuideline } from './utils/naming-conventions';
import { LegendData } from './graph/legend-helpers';
import { VperTable } from './graph/vper-table';
import { SamplesGraphElementsProvider } from './graph/graph-elements/samples-provider';
import { VperPeriodGraphElementsProvider } from './graph/graph-elements/vper-periods-provider';
import { DustSamplesGraphElementsProvider } from './graph/graph-elements/dust-provider';
import { ReportComments } from './components/report/comments/ReportComments';
import { LeqAlarmTable } from './components/report/leqAlarmTable/LeqAlarmTable';
import {
    HighestReadingTable,
    HighestAxisReadingTable,
} from './components/report/highestReadingTable/HighestReadingTable';
import { NotImplementedError } from './utils/errors';
import globalProfile, { ProfileProvider } from './profile';

class BaseReportElement {
    constructor(type) {
        this.type = type;
    }

    getReactElement(_key, _settings) {
        throw new NotImplementedError();
    }

    render(_element, _settings) {
        throw new NotImplementedError();
    }
}

class ReactReportElement extends BaseReportElement {
    constructor(type, reactRenderFunction) {
        super(type);
        this.reactRenderFunction = reactRenderFunction;
    }

    getReactElement(settings, key) {
        let setReady = null;
        const readyPromise = new Promise((resolve) => {
            setReady = resolve;
        });

        return [this.reactRenderFunction(settings, key, setReady), readyPromise];
    }
}

function VanillaReportElementWrapperComponent({ reportElement, settings, setReady, className }) {
    const elementRef = useRef();

    useEffect(() => {
        if (!elementRef.current) {
            return;
        }

        reportElement.render(elementRef.current, settings).then(setReady);
    }, [reportElement, settings, setReady]);

    return <div ref={elementRef} className={className} />;
}
VanillaReportElementWrapperComponent.propTypes = {
    reportElement: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    setReady: PropTypes.func.isRequired,
    className: PropTypes.string,
};

// Basically the non-React way of rendering report elements. The renderFunction gets called
class VanillaReportElement extends BaseReportElement {
    constructor(type, renderFunction, className) {
        super(type);
        this.renderFunction = renderFunction;
        this.className = className;
    }

    getReactElement(settings, key) {
        let setReady = null;
        const readyPromise = new Promise((resolve) => {
            setReady = resolve;
        });

        return [
            <VanillaReportElementWrapperComponent
                key={key}
                reportElement={this}
                settings={settings}
                setReady={setReady}
                className={this.className}
            />,
            readyPromise,
        ];
    }

    render(element, settings) {
        return this.renderFunction(element, settings);
    }
}

class ReportRenderer {
    constructor() {
        this.renderers = [];
    }

    add(renderer) {
        this.renderers.push(renderer);
    }

    find(type) {
        return this.renderers.find((r) => r.type === type);
    }

    execute(type, element, settings) {
        const renderer = this.find(type);

        if (!renderer) {
            throw new Error(`Renderer for graph type ${type} not found.`);
        }

        return renderer.render(element, settings);
    }

    getReactElement(elementsToRender) {
        const readyPromises = [];

        const reactElements = elementsToRender.map(({ settings }, index) => {
            const elementRenderer = this.find(settings.content);

            const [element, promise] = elementRenderer.getReactElement(settings, index);

            readyPromises.push(promise);

            return element;
        });

        // An array of React elements is also considered an element.
        const reactElement = reactElements;

        return [reactElement, Promise.all(readyPromises)];
    }
}

// This function will render the graphs for the old report, new report,
// and the graph preview page.
async function renderReportElements(moduleOptions, elementsToRender, elementForReact = null) {
    // Do nothing when we dont have graphs to render.
    if (elementsToRender.length === 0) {
        // Let Chromium know we are ready.
        setGraphLoadedFlag(Promise.resolve());
        return null;
    }

    const {
        measuringPoint,
        viewStartTs,
        viewEndTs,
        preferredGraphType,
        valueScale,
        valueScaleEquation,
        frequencyScale,
        frequencyGraphScaleType,
        hiddenLines,
        getLineUrl,
        getAlarmsUrl,
        dataType,
        overrides,
    } = moduleOptions;

    // If this method is invoked via Storybook, then the profile will be supplied
    // via the module options. If this method is invoked via a Django view, then
    // we need to pick the global profile.
    const profile = moduleOptions.profile || globalProfile;

    overrides$.next(overrides);

    const data = new DataSet(measuringPoint.id, getLineUrl, getAlarmsUrl, {
        name: measuringPoint.name,
        deviceName: measuringPoint.deviceName,
        swarmType: measuringPoint.swarmType,
        permission: measuringPoint.permission,
        enableFetchTraces: false,
        dataType,
        reportMode: true,
    });
    data.setVisibleMinMax(Math.floor(viewStartTs), Math.ceil(viewEndTs));
    data.checkDataUpdateNeeded(true);

    dataSetGuideLine(data).subscribe((guideLine) => {
        applyNamingConventionsByGuideline(guideLine);
    });

    const scales = new Scales();
    const legendData = new LegendData();

    if (valueScale) {
        // In assets/js/sbr-page.js:371 this value is revertDistanceConverter'd and so
        // here we have to distanceConverter it again. Or should we not even revert it
        // on the other side and just keep it in the user's units. The more we convert
        // the more floating point rounding errors we might introduce. At this point
        // I can't oversee if that acceleration_scale value (as it is still called in
        // sbr-page.js) is used for other purposes too. It doesn't seem to. And so should
        // we maybe also rename the value here or there so that they get the same name
        // and are easier to match to one another?
        scales.set(dataType, distanceConverter(valueScale));
    }

    if (frequencyScale) {
        scales.set('fdom', frequencyScale);
    }

    const margin = {
        top: 20,
        right: 0,
        bottom: 40,
        left: 55,
    };

    const baseConfig = {
        margin,
        scales,
        legendData,
        showTooltips: false,
        zoomTransitionDuration: 0,
    };

    const equations = {
        vibration(n) {
            return n.max_vibration * 1.2;
        },
        alarm(n) {
            return n.max_alarm * 1.2;
        },
        max(n) {
            return max([n.max_alarm, n.max_vibration]) * 1.2;
        },
    };

    // Hide data.
    hiddenLines.forEach((hiddenLine) => {
        legendData.setHidden(hiddenLine, true);
    });

    // Remove PM10 information legend item as that has no purpose in reports.
    legendData.removeType('pm10-information');

    // Init autoscaling.
    (() => {
        // We only want to do autoscaling when there is no preferred valueScale.
        if (valueScale) {
            // Preferred valueScale is set, skip autoscaling.
            return;
        }

        const maxVibration$ = combineLatest(
            [
                SamplesGraphElementsProvider,
                VperPeriodGraphElementsProvider,
                DustSamplesGraphElementsProvider,
            ].map((Provider) => Provider.getGraphElementsObservableForDataSet(data, legendData))
        ).pipe(
            map((arrayOfGraphElements) => arrayOfGraphElements.flatMap(identity)),
            map((allGraphElements) => max(lodashMap(allGraphElements, 'value')) || 0)
        );

        fromEvent(data, 'updated')
            .pipe(
                combineLatestWith(maxVibration$),
                map(([_event, maxVibration]) =>
                    // Do an automatic calculation on max value + 20%.
                    ({
                        max_vibration: maxVibration,
                        max_alarm: max([
                            max(
                                data.alarmLines?.map((arr) => max(arr.map(([vtop, _freq]) => vtop)))
                            ) || 0,
                            max(data.categoryLine?.map(([vtop, _freq]) => vtop)) || 0,
                        ]),
                    })
                ),
                distinctUntilChanged((previous, current) => isEqual(previous, current)),
                map((maxData) => {
                    const calculatedValueScale = equations[valueScaleEquation](maxData);

                    if (!calculatedValueScale) {
                        // Autoscaling failed, no data!
                        return;
                    }

                    scales.set(dataType, distanceConverter(calculatedValueScale));
                })
            )
            .subscribe();
    })();

    const reportRenderer = new ReportRenderer();

    reportRenderer.add(
        new VanillaReportElement(
            'velocity',
            async (element, settings) => {
                const graph = new ValueTimeGraph({
                    ...baseConfig,
                    name: 'bar',
                    data,
                    graphType: preferredGraphType,
                    showBrushGraph: false,

                    buildContainer: true,
                    container: element,
                    containerHeight: settings.height,
                    containerWidth: settings.width,
                });

                await graph.ready;

                // Make sure the testing helpers work with this graph initialization method.
                window.timeGraph = graph;

                return graph;
            },
            'graph pure-u-1'
        )
    );

    reportRenderer.add(
        new VanillaReportElement(
            'frequency',
            async (element, settings) => {
                const graph = new ValueFrequencyGraph({
                    ...baseConfig,
                    name: 'dot',
                    data,
                    dotSize: 3,
                    scaleType$: of(frequencyGraphScaleType),
                    buildContainer: true,
                    container: element,
                    containerHeight: settings.height,
                    containerWidth: settings.width,
                    containerExactSizeIsImportant: settings.exactSizeIsImportant,
                });

                await graph.ready;

                // Make sure the testing helpers work with this graph initialization method.
                window.frequencyGraph = graph;

                return graph;
            },
            'graph pure-u-1'
        )
    );

    reportRenderer.add(
        new VanillaReportElement(
            'veff_day',
            async (element, settings) => {
                const graph = new VeffDayGraph({
                    ...baseConfig,
                    name: 'veffDay',
                    data,
                    dotSize: 4,
                    buildContainer: true,
                    container: element,
                    containerHeight: settings.height,
                    containerWidth: settings.width,
                });

                await graph.ready;

                return graph;
            },
            'graph pure-u-1'
        )
    );

    reportRenderer.add(
        new VanillaReportElement(
            'vper_table',
            async (element, _settings) => {
                const table = new VperTable(element, data, legendData, false);

                return table;
            },
            'generic-block'
        )
    );

    reportRenderer.add(
        new ReactReportElement('comments', (_settings, key, setReady) => {
            // No need to set readiness from within the comment component, as it uses
            // queries in the dataSet. The report will await rendering until all queries
            // in the dataSet have finished loading.
            setReady();
            return <ReportComments key={key} dataSet={data} />;
        })
    );

    reportRenderer.add(
        new ReactReportElement('leq_alarm_table', (_settings, key, setReady) => (
            <LeqAlarmTable key={key} dataSet={data} setReady={setReady} />
        ))
    );

    reportRenderer.add(
        new ReactReportElement('highest_axis_reading_table', (_settings, key, setReady) => (
            <HighestAxisReadingTable key={key} dataSet={data} setReady={setReady} />
        ))
    );

    reportRenderer.add(
        new ReactReportElement('highest_reading_table', (_settings, key, setReady) => (
            <HighestReadingTable key={key} dataSet={data} setReady={setReady} />
        ))
    );

    // Holds all returned report elements from the rendering functions.
    let graphs;

    // Check if `elementForReact` is provided. If so, fetch all render-ready
    // report elements using `getReactElement` and render them to the specified
    // DOM element. This rendering approach is utilized by the V1 report template
    // and is also used in Storybook to simulate the V1 report appearance.
    if (elementForReact) {
        const [element, readyPromise] = reportRenderer.getReactElement(elementsToRender);
        ReactDOM.render(
            <ProfileProvider profile={profile}>{element}</ProfileProvider>,
            elementForReact
        );

        graphs = await readyPromise;
    } else {
        // This block handles the non-React method of rendering report elements.
        // Instead of rendering a single, large React component containing all
        // report elements, we invoke individual render methods on separate DOM
        // elements provided from the outside. This style of rendering is adopted
        // by the V2 report, primarily initiated via `reportExportPageForApi`, and
        // is used by the PDFGenerator to generate preview images of graphs through
        // the `graphPreview` method.
        const renderPromises = elementsToRender.map(({ element, settings }) =>
            reportRenderer.execute(settings.content, element, settings)
        );

        graphs = await Promise.all(renderPromises);
    }

    // Store all the graph instances for debugging purposes.
    window.graphs = graphs;

    // Let Chromium know we are ready.
    setGraphLoadedFlag(data.ready);

    // Return the created graphs and dataSet. This return value is only used inside Storybook tests.
    return {
        dataSet: data,
        graphs,
    };
}

export const reportExportPage = (module) => {
    const defaultHeight = 285;
    const defaultWidth = 680;

    const { options } = module;

    const reportElements = [
        {
            settings: {
                content: 'velocity',
                height: defaultHeight,
                width: defaultWidth,
            },
        },
        {
            settings: {
                content: 'frequency',
                height: defaultHeight,
                width: defaultWidth,
            },
        },
        {
            settings: {
                content: 'veff_day',
                height: defaultHeight,
                width: defaultWidth,
            },
        },
        {
            settings: { content: 'leq_alarm_table' },
        },
        {
            settings: { content: 'highest_axis_reading_table' },
        },
        {
            settings: { content: 'highest_reading_table' },
        },
        {
            settings: { content: 'comments' },
        },
        {
            settings: { content: 'vper_table' },
        },
    ];

    return renderReportElements(options, reportElements, options.element);
};

export const graphPreview = (module) => {
    // VeffDayGraph only shows when DataSet has dataType veff selected.
    if (module.options.content === 'veff_day') {
        module.options.dataType = 'veff';
    }

    renderReportElements(module.options, [
        {
            element: document.getElementsByTagName('body')[0],
            settings: {
                content: module.options.content,
                height: 350,
                width: 780,
                exactSizeIsImportant: true,
            },
        },
    ]);
};

export const reportExportPageForApi = (module) => {
    const graphsInPage = document.querySelectorAll('[data-graph-settings]');

    const graphsForRenderer = Array.from(graphsInPage).map((graph) => {
        const graphSettings = JSON.parse(graph.getAttribute('data-graph-settings'));

        return {
            element: graph,
            settings: {
                ...graphSettings,
                height: graph.getAttribute('height'),
                width: graph.getAttribute('width'),
                exactSizeIsImportant: true,
            },
        };
    });

    renderReportElements(module.options, graphsForRenderer);
};
