import PropTypes from 'prop-types';
import { catchError, combineLatest, map, Observable, of, startWith, switchMap } from 'rxjs';
import { useObservable, useObservableState } from 'observable-hooks';
import { first, isArray } from 'lodash-es';
import { subWeeks } from 'date-fns';
import { useMemo } from 'react';
import { BaseDataSet } from '../../graph/dataset';
import { PERMISSION } from '../pages/sharing/utils';
import { swarmTypeDataTypes } from '../../graph/data-types';
import { SwarmType } from '../../enums';
import { createEditUrl } from '../pages/measuringPoint/utils';
import { featureCheckSwarm$ } from '../pages/measuringPoint/featureCheck';
import warning from '../../utils/logger';

const now = new Date().getTime();
const weekBack = subWeeks(now, 1).getTime();

function hasDeviceSoundClipSupport$(deviceName) {
    return featureCheckSwarm$(deviceName).pipe(
        catchError((error) => {
            warning(error);
            return of([]);
        }),
        map((response) => {
            const unavailableFeatures = first(response)?.unavailableFeatures;

            if (!isArray(unavailableFeatures)) {
                return false;
            }

            const soundClipsDisabled = unavailableFeatures.some(
                (feature) => feature.field === 'soundClipAlarmSource'
            );

            return !soundClipsDisabled;
        }),
        startWith(false)
    );
}

function useEvaluatedButtons(buttons) {
    return useObservableState(
        useObservable(
            (input$) =>
                input$.pipe(
                    switchMap(([innerButtons]) =>
                        combineLatest(
                            innerButtons.map((button) =>
                                button.show$.pipe(
                                    switchMap((show) =>
                                        (show ? button.href$ : of(null)).pipe(
                                            map((href) => ({ ...button, show, href }))
                                        )
                                    )
                                )
                            )
                        )
                    )
                ),

            [buttons]
        ),
        []
    );
}

export function RelatedPagesButtons({ dataSet, overrides$, getShowTracesUrl }) {
    const buttons = useMemo(
        () => [
            {
                title: gettext('CONFIGURATION'),
                newTab: false,
                show$: dataSet.permission$.pipe(
                    map((permission) => permission === PERMISSION.FULL_ACCESS)
                ),
                href$: dataSet.sensor$.pipe(
                    map((id) => createEditUrl(id, dataSet.swarmType, false))
                ),
            },
            {
                title: gettext('RECENT_TRACES'),
                newTab: true,
                // ID is needed for Selenium tests.
                id: 'id_to_traces',
                show$: dataSet.dataType$.pipe(
                    map((dataType) => swarmTypeDataTypes[SwarmType.VIBRATION].includes(dataType))
                ),
                href$: combineLatest([dataSet.sensor$, dataSet.dataType$, overrides$]).pipe(
                    map(([id, dataType, overrides]) =>
                        getShowTracesUrl(id, weekBack, now, dataType, overrides || {})
                    )
                ),
            },
            {
                title: gettext('SOUND_CLIPS'),
                newTab: true,
                show$: combineLatest([dataSet.dataType$, dataSet.deviceName$]).pipe(
                    switchMap(([dataType, deviceName]) => {
                        if (
                            !deviceName ||
                            !swarmTypeDataTypes[SwarmType.SOUND].includes(dataType)
                        ) {
                            return of(false);
                        }

                        return hasDeviceSoundClipSupport$(deviceName);
                    })
                ),
                href$: dataSet.sensor$.pipe(map((id) => `/sound_clips/${id}/${weekBack}/${now}`)),
            },
        ],
        [
            dataSet.dataType$,
            dataSet.deviceName$,
            dataSet.permission$,
            dataSet.sensor$,
            dataSet.swarmType,
            getShowTracesUrl,
            overrides$,
        ]
    );

    const evaluatedButtons = useEvaluatedButtons(buttons);

    return (
        <div className="flex gap-x-4">
            {evaluatedButtons
                .filter(({ show }) => show)
                .map(({ title, href, newTab, id }) => {
                    const props = {};

                    if (newTab) {
                        props.target = '_blank';
                    }

                    if (id) {
                        props.id = id;
                    }

                    return (
                        <a
                            key={title}
                            href={href}
                            className="rounded-2xl bg-primary px-6 py-1 text-sm no-underline"
                            {...props}
                        >
                            {title}
                        </a>
                    );
                })}
        </div>
    );
}
RelatedPagesButtons.propTypes = {
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
    overrides$: PropTypes.instanceOf(Observable).isRequired,
    getShowTracesUrl: PropTypes.func.isRequired,
};
