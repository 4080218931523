import { Routes, Route, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OverviewPage } from './OverviewPage';
import { SwarmType } from '../../../enums';

import '../../../index.css';
import GenericForm from './GenericForm';

// Wrapper around `GenericForm` that assigns a unique key for each combination of
// props possible. Assigning a unique key ensures that `GenericForm` is remounted
// instead of simply updating the props of an already mounted instance. Simply
// updating the props of an already mounted `GenericForm` can lead to issues such
// as leftover state (e.g., form values) from previous measuring points, or a
// ConfigHelper race condition where it attempts to hide SWARM Vibrations fields
// while the form already displays SWARM Air fields.
function GenericFormWrapper({ swarmType, isProject }) {
    const { id } = useParams();
    const key = `${swarmType}_${isProject}_${id ?? 'create'}`;
    return <GenericForm key={key} swarmType={swarmType} isProject={isProject} />;
}
GenericFormWrapper.propTypes = {
    swarmType: PropTypes.oneOf(Object.values(SwarmType)).isRequired,
    isProject: PropTypes.bool.isRequired,
};

function GenericRoutes({ isProject }) {
    return (
        <Routes>
            {Object.values(SwarmType).flatMap((swarmType) => [
                <Route
                    key={`create_${swarmType}`}
                    path={`create/${swarmType}`}
                    element={<GenericFormWrapper swarmType={swarmType} isProject={isProject} />}
                />,
                <Route
                    key={`edit_${swarmType}`}
                    path={`edit/${swarmType}/:id`}
                    element={<GenericFormWrapper swarmType={swarmType} isProject={isProject} />}
                />,
            ])}

            {/**
             * The routes below are not accessible for now because the Django
             * router is not pointing to the JsPageView for this path.
             */}
            <Route index element={<OverviewPage />} />
        </Routes>
    );
}
GenericRoutes.propTypes = {
    isProject: PropTypes.bool.isRequired,
};

export function MeasuringPointRoutes() {
    return <GenericRoutes isProject={false} />;
}

export function ProjectRoutes() {
    return <GenericRoutes isProject={true} />;
}
