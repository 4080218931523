import { useState } from 'react';

function useOrderAndPaginationControl(pageSize) {
    const [orderBy, setOrderBy] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    return {
        orderBy,
        setOrderBy,
        pageSize,
        currentPage,
        setCurrentPage,
        totalCount,
        setTotalCount,
        pageCount: Math.ceil(totalCount / pageSize),
    };
}

export default useOrderAndPaginationControl;
