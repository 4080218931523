/* eslint no-console: ["error", { allow: ["log", "warn"] }] */

import { isEmpty } from 'lodash-es';
import rollbar from './rollbar';

const warning = (message, extraData, rollbarUUIDCallback) => {
    const args = [message];

    if (!isEmpty(extraData)) {
        args.push(extraData);
    }

    if (rollbar) {
        // When examining the source of Rollbar, every log function loops over the arguments.
        // The first string argument it finds will be used as the Rollbar message,
        // the first Error instance argument will be used as `err`,
        // the first function argument will be used as a callback to report back the Rollbar UUID
        // it created, and the first array or object argument will be used as `extraArgs`.
        // Thus, the order of arguments does not matter, only their types.
        // Apart from receiving the Rollbar UUID via the callback, the UUID can also be retrieved
        // via the return value of the log function. In our case, we are going to use that.
        // Source:
        // https://github.com/rollbar/rollbar.js/blob/d8ea8956a041b15d4d6a1e9153f67ae3cc00ac7d/src/utility.js#L456

        // When extraData is `{postId: 1}` it will appear as `message.extra.postId` in the
        // Occurrences tab.

        const item = rollbar.warning(...args);
        const uuid = item?.uuid;

        // If we received an UUID and the caller would like to receive it, send it.
        if (rollbarUUIDCallback && uuid) {
            rollbarUUIDCallback(uuid);
        }

        return;
    }

    console.warn(...args);
};

export default warning;
