import { useMemo } from 'react';
import { defaultPropTypes } from '../utils';
import ChoiceField from './ChoiceField';

function BooleanField({ fieldOptions }) {
    const booleanFieldOptions = useMemo(() => {
        const options = fieldOptions.settings?.invertedOnOffChoices
            ? [
                  [true, gettext('OFF')],
                  [false, gettext('ON')],
              ]
            : [
                  [true, gettext('ON')],
                  [false, gettext('OFF')],
              ];

        return {
            ...fieldOptions,
            settings: {
                ...fieldOptions.settings,
                options: new Map(options),
            },
        };
    }, [fieldOptions]);

    return <ChoiceField fieldOptions={booleanFieldOptions} />;
}
BooleanField.propTypes = defaultPropTypes;

export default BooleanField;
