import PropTypes from 'prop-types';
import { get, property } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import SkeletonLoader from '../skeletonLoader/SkeletonLoader';

const blockClasses = 'w-full drop-shadow-sm';
const tableHeaderCellClasses = 'px-2 text-xs leading-6 text-gray-400 bg-gray-100 font-semibold';
const blockHeaderContentClasses = 'w-32 flex-initial text-neutral-800';

function SortButtons({ columnName, orderBy, setOrderBy }) {
    return (
        <span className="mb-0.5 inline-flex flex-col pl-1 align-middle">
            {[
                {
                    orderPrefix: '',
                    buttonClassName: 'h-1.5',
                    icon: faSortUp,
                    iconClassName: `block h-3`,
                },
                {
                    orderPrefix: '-',
                    buttonClassName: 'relative h-1.5 overflow-hidden',
                    icon: faSortDown,
                    iconClassName: `absolute bottom-0 block h-3`,
                },
            ].map(({ orderPrefix, buttonClassName, icon, iconClassName }, index) => {
                const whenSelected = `${orderPrefix}${columnName}`;
                return (
                    <button
                        key={`${columnName}-${index}`}
                        onClick={() => {
                            setOrderBy((current) =>
                                // Unset orderBy when clicking while already selected.
                                current === whenSelected ? null : whenSelected
                            );
                        }}
                        className={buttonClassName}
                    >
                        <FontAwesomeIcon
                            icon={icon}
                            className={`${iconClassName} ${
                                orderBy === `${whenSelected}` ? 'text-black' : ''
                            }`}
                        />
                    </button>
                );
            })}
        </span>
    );
}
SortButtons.propTypes = {
    columnName: PropTypes.string.isRequired,
    orderBy: PropTypes.string,
    setOrderBy: PropTypes.func.isRequired,
};

function TableHeader({ columns, orderAndPaginationControl = null }) {
    return (
        <thead>
            <tr className="ml-2.5 mt-2.5 h-6 px-1">
                {columns.map((column, columnIndex) => (
                    <th
                        key={columnIndex}
                        className={
                            columnIndex + 1 === columns.length
                                ? `${tableHeaderCellClasses} text-right`
                                : `${tableHeaderCellClasses} text-left`
                        }
                    >
                        {column.title}
                        {column?.showSortIcon && orderAndPaginationControl && (
                            <SortButtons
                                columnName={column.key}
                                orderBy={orderAndPaginationControl.orderBy}
                                setOrderBy={orderAndPaginationControl.setOrderBy}
                            />
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    );
}
TableHeader.propTypes = {
    columns: PropTypes.array,
    orderAndPaginationControl: PropTypes.object,
};

function isLastColumn(index, columns) {
    return index + 1 === columns.length;
}

function TableBody({ columns, data, keyFunction }) {
    return (
        <tbody>
            {data.map((rowData) => (
                <tr key={keyFunction(rowData)} className="py-4">
                    {columns.map((column, columnIndex) => (
                        <td
                            key={columnIndex}
                            className={`h-6 px-2 pt-4 text-xs leading-6 ${
                                isLastColumn(columnIndex, columns) && 'text-right'
                            }`}
                        >
                            {column.render
                                ? column.render(column, rowData)
                                : get(rowData, column.key, '')}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
}
TableBody.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyFunction: PropTypes.func.isRequired,
};

function BlockHeader({ header }) {
    return (
        <div
            className={`flex h-11 flex-row justify-between justify-items-center gap-2.5 rounded-tr px-2.5 py-2 font-black -tracking-widened ${blockClasses}`}
        >
            <div className={blockHeaderContentClasses}>
                <label className="h-6 w-16 text-base font-semibold leading-6">{header}</label>
            </div>
            <div className={`${blockHeaderContentClasses} text-right`}>
                <label className="h-5 w-28 text-sm font-bold leading-5">
                    {gettext('DOWNLOAD_CSV')}
                </label>
                <FontAwesomeIcon icon={faDownload} className="h-3 w-3 text-gray-400" />
            </div>
        </div>
    );
}
BlockHeader.propTypes = {
    header: PropTypes.string.isRequired,
};

const defaultKeyFunction = property('id');

function Table({
    data,
    columns,
    header,
    showHeader = false,
    orderAndPaginationControl = null,
    loading,
    showBorder = true,
    keyFunction = defaultKeyFunction,
}) {
    return (
        <div className={`rounded ${showBorder ? 'border' : null}`}>
            {showHeader && <BlockHeader header={header} />}
            <hr className="border-gray-200 pb-2" />
            <div className="px-1.5">
                {loading ? (
                    <div className="pb-1.5 text-center">
                        <SkeletonLoader count={3} wrapperClassName="p-2" />
                    </div>
                ) : (
                    <table className={`${blockClasses} mb-2.5 rounded-bl`}>
                        <TableHeader
                            columns={columns}
                            orderAndPaginationControl={orderAndPaginationControl}
                        ></TableHeader>
                        <TableBody columns={columns} data={data} keyFunction={keyFunction} />
                    </table>
                )}
            </div>
        </div>
    );
}

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    header: PropTypes.string,
    showHeader: PropTypes.bool,
    orderAndPaginationControl: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    showBorder: PropTypes.bool,
    keyFunction: PropTypes.func,
};

export default Table;
