import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { difference, isEmpty, isEqual, omit } from 'lodash-es';

import {
    extractEnabledFields,
    FormContext,
    useWatchNestedField,
    NON_FIELD_ERRORS,
    ErrorToNotificationsLink,
} from './utils';
import { resolver } from './validation';
import Button, { ButtonContainer } from '../form/Button';
import {
    ConfigHelper,
    DisabledConfigHelper,
    useConfigHelperWithReactFormHook,
} from '../pages/measuringPoint/configHelper';

const Form = ({
    projectIgnoreFields,
    information,
    defaultValues,
    InjectableComponent,
    DataLink,
    dataLinkProps,
    configHelper = new DisabledConfigHelper(),
    showSaveButton = true,
    children,
}) => {
    const [onSubmitFunction, setOnSubmitFunction] = useState();

    const reactHookForm = useForm({
        mode: 'all',
        defaultValues,
        resolver,
    });

    const { handleSubmit, clearErrors, setError, setValue, getValues, watch } = reactHookForm;

    const [values, setValues] = useState({});

    const [parentValues, setParentValues] = useState(null);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [loading, setLoading] = useState(false);

    const overriddenValuesBackup = useRef(new Map());

    const setOverriddenField = useCallback(
        (field, value, removeFromBackup) => {
            setValue(`override.${field}`, value);

            // `removeFromBackup` is used by the `ChoiceField` to ensure that once a user selects
            // a non-project option, the selected option does not revert to the value that was set
            // before the project was chosen. This essentially prevents the behavior described below
            // in `Revert field values`.
            if (removeFromBackup) {
                overriddenValuesBackup.current.has(field) &&
                    overriddenValuesBackup.current.delete(field);
            }
        },
        [setValue]
    );

    const setOverriddenFields = useCallback(
        (overriddenFields) => {
            overriddenFields.forEach((field) => {
                setOverriddenField(field, true);
            });
        },
        [setOverriddenField]
    );

    const mergedDataLinkProps = useMemo(
        () => ({
            ...dataLinkProps,
            setOnSubmitFunction,
            setError,
            setErrorOccurred,
            setLoading,
            setValues,
            setParentValues,
            setOverriddenFields,
        }),
        [
            dataLinkProps,
            setOnSubmitFunction,
            setError,
            setLoading,
            setValues,
            setParentValues,
            setOverriddenFields,
        ]
    );

    const overriddenFields = useWatchNestedField(watch, 'override', {});

    useEffect(() => {
        if (isEmpty(values)) {
            return;
        }

        Object.entries(values).forEach(([name, value]) => {
            setValue(name, value, { shouldValidate: true });
        });
    }, [values, setValue]);

    useEffect(() => {
        const subscription = watch((incoming) => {
            if (!parentValues) {
                // We don't have to do anything when there is no project selected.
                return;
            }

            // When a change comes in and the value of a field is different from
            // the project field, it means we've overridden the project settings.
            // In that case, we can remove the field from the `overriddenValuesBackup`
            // as we don't want that field to get reverted when the project gets unset.
            const toBeRemoved = Object.fromEntries(
                Array.from(overriddenValuesBackup.current.keys()).map((name) => [
                    name,
                    !isEqual(parentValues[name], incoming[name]),
                ])
            );

            extractEnabledFields(toBeRemoved).forEach((name) => {
                overriddenValuesBackup.current.delete(name);
            });
        });

        return subscription.unsubscribe;
    }, [watch, parentValues]);

    useEffect(() => {
        const removeParentFields = [...extractEnabledFields(overriddenFields), 'name', 'id'];

        const newParentValues = omit(parentValues, removeParentFields);

        const toBeReverted = difference(
            Array.from(overriddenValuesBackup.current.keys()),
            Object.keys(newParentValues)
        );

        // Revert field values for fields that got overridden.
        toBeReverted.forEach((name) => {
            setValue(name, overriddenValuesBackup.current.get(name));
            overriddenValuesBackup.current.delete(name);
        });

        Object.entries(newParentValues).forEach(([name, value]) => {
            // Store a backup of the 'before project' field value.
            if (!overriddenValuesBackup.current.has(name)) {
                overriddenValuesBackup.current.set(name, getValues(name));
            }

            // Set the field value to the project field value.
            setValue(name, value);
        });
    }, [parentValues, setValue, overriddenFields, getValues]);

    const [showAreYouSureToOverrideMessage, setShowAreYouSureToOverrideMessage] = useState(true);

    const askAreYouSureToOverrideMessageOnce = useCallback(() => {
        const message = gettext('Are you sure you want to override the project settings?');
        if (showAreYouSureToOverrideMessage) {
            // eslint-disable-next-line no-restricted-globals, no-alert
            if (!confirm(message)) {
                return false;
            }

            setShowAreYouSureToOverrideMessage(false);
        }

        return true;
    }, [showAreYouSureToOverrideMessage]);

    useConfigHelperWithReactFormHook(configHelper, reactHookForm);

    const triggerSubmit = useCallback(
        (...args) => {
            clearErrors(NON_FIELD_ERRORS);
            return handleSubmit(onSubmitFunction)(...args);
        },
        [clearErrors, onSubmitFunction, handleSubmit]
    );

    const context = useMemo(
        () => ({
            parentValues,
            setOverriddenField,
            projectIgnoreFields,
            information,
            askAreYouSureToOverrideMessageOnce,
            configHelper,
            triggerSubmit,
        }),
        [
            parentValues,
            setOverriddenField,
            projectIgnoreFields,
            information,
            askAreYouSureToOverrideMessageOnce,
            configHelper,
            triggerSubmit,
        ]
    );

    return (
        <FormProvider {...reactHookForm}>
            <FormContext.Provider value={context}>
                <ErrorToNotificationsLink />
                {DataLink && <DataLink {...mergedDataLinkProps} />}
                <div className="main-container-new">
                    {loading && <p>{gettext('LOADING')}</p>}
                    {errorOccurred && <p>{gettext('ERROR')}</p>}
                </div>
                {!loading && !errorOccurred && (
                    <form onSubmit={triggerSubmit}>
                        {children}

                        {showSaveButton && (
                            <div className="even:bg-neutral-200">
                                <div className="main-container-new py-8">
                                    <ButtonContainer>
                                        <Button>{gettext('SAVE')}</Button>
                                        <Button variant="secondary" href="/measuring_point/">
                                            {gettext('CANCEL')}
                                        </Button>
                                    </ButtonContainer>
                                </div>
                            </div>
                        )}
                    </form>
                )}
                {InjectableComponent && <InjectableComponent />}
            </FormContext.Provider>
        </FormProvider>
    );
};

Form.propTypes = {
    children: PropTypes.node.isRequired,
    configHelper: PropTypes.instanceOf(ConfigHelper),
    DataLink: PropTypes.func,
    dataLinkProps: PropTypes.shape({
        queries: PropTypes.object,
        pk: PropTypes.number,
        onSubmitSuccessful: PropTypes.func,
        convertDataIntoFormValues: PropTypes.func,
    }),
    InjectableComponent: PropTypes.func,
    defaultValues: PropTypes.object,
    information: PropTypes.object,
    projectIgnoreFields: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
    ),
    showSaveButton: PropTypes.bool,
};

export default Form;
