import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function LoadingSpinner({ className, ...props }) {
    return <FontAwesomeIcon icon={faSpinner} className={`animate-spin ${className}`} {...props} />;
}

LoadingSpinner.propTypes = {
    className: PropTypes.string,
};

export default LoadingSpinner;
