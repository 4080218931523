import { isNull, range, isEmpty } from 'lodash-es';
import { utcToZonedTime, toDate, format } from 'date-fns-tz';
import {
    isWithinInterval,
    subMilliseconds,
    areIntervalsOverlapping,
    getDay,
    roundToNearestMinutes,
    add,
} from 'date-fns';
import { faCheck, faXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const isoFormatWithoutZ = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const calendarTimezone = 'Etc/UTC';

// Function that converts a date to a calendar date in the UTC timezone.
// This ensures that dates are standardized by removing any timezone offset.
// For example:
// 2024-03-31 00:00:00+01:00 becomes 2024-03-31 00:00:00+00:00
// 2024-03-31 23:59:59+02:00 becomes 2024-03-31 23:59:59+00:00
// This helps in eliminating issues with variable-length days (e.g., 23 or 25 hours)
// caused by daylight saving time changes, ensuring consistent formatting.
export function toCalendarDate(date) {
    return toDate(format(date, isoFormatWithoutZ), { timeZone: calendarTimezone });
}

export function createTimeSpanObject(from, to) {
    return [{ timeSpan: { from, to } }];
}

export function getProgressBarColorClass(measuredValue, warningLevel, alarmLevel) {
    if (isNull(measuredValue)) return null;

    if (measuredValue < warningLevel) return 'bg-success';
    if (measuredValue >= alarmLevel) return 'bg-error';
    return 'bg-warning';
}

export function getLdenIcon(measuredValue, warningLevel, alarmLevel) {
    if (measuredValue < warningLevel) return { icon: faCheck, iconColor: 'text-success' };
    if (measuredValue > alarmLevel) return { icon: faXmark, iconColor: 'text-error' };
    return { icon: faTriangleExclamation, iconColor: 'text-warning' };
}

export function getEventInfo(events, eventName) {
    const event = events?.find(({ __typename }) => __typename === eventName);
    return {
        date: event?.timestamp ? new Date(event.timestamp) : null,
        uploads: event?.uploading ?? null,
    };
}

export function getDayPeriod(locale, period) {
    return locale.localize.dayPeriod(period);
}

export function generateBlocksPerDay({ scalesPerDay, timezone, blocksToRender }) {
    const days = Array.from(scalesPerDay.keys());
    const perDay = new Map(days.map((day) => [day, []]));

    blocksToRender.forEach(({ Element, data, elementProps }) => {
        // Separate the periods that could span multiple days into to be rendered blocks per day.
        data.forEach((period) => {
            const fromInCalendar = toCalendarDate(utcToZonedTime(period.timeSpan.from, timezone));
            const toInCalendar = toCalendarDate(utcToZonedTime(period.timeSpan.to, timezone));

            days.forEach((day) => {
                if (areIntervalsOverlapping({ start: fromInCalendar, end: toInCalendar }, day)) {
                    const startsOnThisDay = isWithinInterval(fromInCalendar, day);
                    const endsOnThisDay = isWithinInterval(subMilliseconds(toInCalendar, 1), day);

                    const start = startsOnThisDay ? fromInCalendar : day.start;
                    const end = endsOnThisDay ? toInCalendar : day.end;

                    const left = scalesPerDay.get(day)(start);
                    const right = scalesPerDay.get(day)(end);

                    perDay.get(day).push({
                        Element,
                        elementProps,
                        props: { period, left, width: right - left },
                    });
                }
            });
        });
    });

    return perDay;
}

export function dateToMilitary(date) {
    return { day: getDay(date), time: parseInt(format(date, 'Hmm'), 10) };
}

export function checkCollision(date, customTemplate, startDate) {
    return customTemplate.some((block) => {
        const interval = { start: block.startDate, end: block.endDate };

        // Check if the date is within the block's interval.
        const isDateInInterval = isWithinInterval(date, interval);

        // Check if the block's startDate is within the interval defined by startDate and date.
        const isStartDateInInterval =
            startDate &&
            isWithinInterval(block.startDate, {
                start: startDate,
                end: date,
            });

        return isDateInInterval || isStartDateInInterval;
    });
}

export function roundDateTo30Minutes(inputDate) {
    // Convert input to a Date object if it's not already one.
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

    // Round to the nearest 30 minutes.
    return roundToNearestMinutes(date, { nearestTo: 30 });
}

function getDuration(days, time) {
    const timeInt = parseInt(time, 10);
    const hours = timeInt / 100;
    const minutes = timeInt % 100;

    return { days, hours, minutes };
}

// First Sunday since epoch. We use Sunday as that is day 0 in the protobuf.
export const templateStartInUtc = new Date('1970-01-04T00:00:00.000Z');

export function extendTemplate(template, repeat) {
    if (isEmpty(template)) return null;
    return range(repeat).flatMap((week) =>
        template.map((timeWindow) => {
            const offset = week * 7;
            const newStartDay = timeWindow.startDay + offset;
            let newEndDay = timeWindow.endDay + offset;

            // Handle the case where the endDay should roll over to the next week.
            if (timeWindow.endDay < timeWindow.startDay) {
                newEndDay += 7;
            }

            return {
                ...timeWindow,
                startDay: newStartDay,
                endDay: newEndDay,
            };
        })
    );
}

export function formatTemplateData(template) {
    if (!template) return [];
    return template.map((timeWindow) => ({
        timeWindow,
        value: null,
        timeSpan: {
            from: add(templateStartInUtc, getDuration(timeWindow.startDay, timeWindow.startTime)),
            to: add(templateStartInUtc, getDuration(timeWindow.endDay, timeWindow.endTime)),
        },
    }));
}

export function getFullDateFromNumber(dayNumber, time) {
    // dayIndex should represent day of the week.
    const dayIndex = dayNumber % 7;

    // Create a date object for the Unix epoch (January 11, 1970)
    const baseDate = new Date('1970-01-11T12:30:00.000Z');

    // Get the date corresponding to the day index.
    const targetDate = new Date(baseDate);
    targetDate.setDate(baseDate.getDate() + dayIndex);

    if (time) {
        const hours = Math.floor(time / 100);
        const minutes = time % 100;

        targetDate.setHours(hours, minutes, 0, 0);
    }

    return targetDate;
}
