/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/**
 * This page is still WIP.
 * TODO:
 *  * Transfer all the cog actions from the original overview page.
 *  * Change <i className="fa fa-cog" /> into <FontAwesomeIcon />
 *  * Create Storybook story where this page functions completely independently.
 */

import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { groupBy, keyBy } from 'lodash-es';
import { SURVEY_BODY_TEXT, SURVEY_URL, SURVEY_HEADER_TEXT } from '../../surveyPopup/utils';
import { BasePage } from '../BasePage';
import { createEditUrl, getSwarmTypeByGraphqlObject } from './utils';
import SurveyPopup from '../../surveyPopup/surveyPopup';
import { SwarmType } from '../../../enums';
import { RoundedButton } from '../../form/Button';

const GET_OVERVIEW = gql`
    query getOverview {
        measuringPoints {
            id
            name
            swarmType
            project
            deviceName
        }
        projects {
            id
            name
            swarmType
        }
    }
`;

const swarmIconClasses = {
    [SwarmType.AIR]: 'swarm-air-icon',
    [SwarmType.VIBRATION]: 'swarm-vibration-icon',
    [SwarmType.SOUND]: 'swarm-sound-icon',
};

const getSwarmIconClass = (object) => {
    if (object) {
        return swarmIconClasses[getSwarmTypeByGraphqlObject(object)];
    }

    return null;
};

function MeasuringPointGroup({ group }) {
    const editProjectUrl =
        group.project && createEditUrl(group.project.id, group.project.swarmType, true);

    return (
        <div className="project main-container-new px-5">
            <div className="flex">
                <h3 className="grow">
                    <div className="project-device">
                        <div className={getSwarmIconClass(group.project)}></div>
                    </div>
                    <div>{group.name}</div>
                </h3>
                {group.project && (
                    <Link to={editProjectUrl}>
                        <RoundedButton color="secondary">Configuration</RoundedButton>
                    </Link>
                )}
            </div>
            <table className="w-full table-auto">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>City</th>
                        <th>Status</th>
                        <th>Device</th>
                        <th className="text-wrap">Last contact</th>
                        <th className="text-wrap">Battery charge</th>
                        <th>Options</th>
                    </tr>
                </thead>

                <tbody>
                    {group.measuringPoints.map((mp) => {
                        const { id, name, swarmType, deviceName } = mp;
                        const editMeasuringPointUrl = createEditUrl(id, swarmType, false);
                        return (
                            <tr key={id} data-measuring-point-id={id}>
                                <td data-label="Name" className="mp-name">
                                    {name}
                                </td>
                                <td data-label="City">Unknown</td>

                                <td data-label="Status" className="status">
                                    <div className="circle">inactive</div>
                                </td>
                                <td data-label="Device">
                                    <div className="name">
                                        <div
                                            className={`${
                                                !deviceName && 'detached-device'
                                            } ${getSwarmIconClass(mp)}`}
                                        ></div>
                                    </div>
                                </td>
                                <td data-label="Last contact" className="server_contact"></td>
                                <td data-label="Battery charge">
                                    <span title="There is no battery state known or the last state was too long ago.">
                                        Unknown
                                    </span>
                                </td>

                                <td data-label="Options" className="text-right">
                                    <RoundedButton href="/sbr/sbrView/#12330">
                                        View data
                                    </RoundedButton>

                                    <Link to={editMeasuringPointUrl}>
                                        <RoundedButton color="secondary">
                                            Configuration
                                        </RoundedButton>
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export function OverviewPage() {
    // TODO: Do something with loading and error states.
    const { _loading, _error, data } = useQuery(GET_OVERVIEW, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    const measuringPointGroups = useMemo(() => {
        const measuringPoints = data?.measuringPoints;
        const projects = keyBy(data?.projects, 'id');

        return Object.entries(groupBy(measuringPoints, 'project')).map(([projectId, mps]) => ({
            key: projectId,
            name:
                projectId === 'null'
                    ? 'Without project'
                    : projects[projectId]?.name ?? 'Unknown project',
            project: projects[projectId],
            measuringPoints: mps,
        }));
    }, [data]);

    return (
        <BasePage title="Measuring point overview">
            {measuringPointGroups.map((group) => (
                <MeasuringPointGroup key={group.key} group={group} />
            ))}
            <SurveyPopup
                headerText={SURVEY_HEADER_TEXT}
                bodyText={SURVEY_BODY_TEXT}
                url={SURVEY_URL}
            />
        </BasePage>
    );
}
