import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { BasePage } from './BasePage';
import warning from '../../utils/logger';

/**
 * The `tryAgain` argument is optional and is not needed if you are manually rendering this page.
 * This function will be provided when an error is detected by an ErrorBoundary,
 * which is wrapped around each element/page served by the router in the `PageContainer`.
 * The `tryAgain` function is sourced from the `react-error-boundary` library.
 *
 * The `error` argument is optional. If it is not provided, and you are manually rendering the page,
 * ensure that you have logged the error before displaying this page.
 * If you do provide the error, it will be logged to Rollbar, and the resulting Rollbar UUID will be
 * shown on the page.
 */
export function ErrorPage({ tryAgain, error }) {
    const [rollbarUuid, setRollbarUuid] = useState();

    useEffect(() => {
        if (!error) {
            return;
        }

        warning(error, null, (uuid) => {
            setRollbarUuid(uuid);
        });
    }, [error]);

    return (
        <BasePage title={gettext('ERROR_PAGE_TITLE')}>
            <div className="main-container-new pb-4">
                <p>{gettext('ERROR_PAGE_BODY')}</p>
                {tryAgain && (
                    <p>
                        <button onClick={tryAgain}>{gettext('TRY_AGAIN')}</button>
                    </p>
                )}
                {rollbarUuid && (
                    <p>
                        <small>
                            {gettext('REFERENCE_CODE')}: {rollbarUuid}
                        </small>
                    </p>
                )}
            </div>
        </BasePage>
    );
}
ErrorPage.propTypes = {
    tryAgain: PropTypes.func,
    error: PropTypes.instanceOf(Error),
};
