import { useMutation, useQuery } from '@apollo/client';
import { cloneDeep, compact, has, isEmpty, range } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { isEmailValid, isPhoneNumberValid } from '../../forms';
import warning from '../../utils/logger';
import { extractEnabledFields, NON_FIELD_ERRORS } from './utils';
import { FieldNotFoundError } from '../pages/measuringPoint/schema-utils';

const useEntity = (getEntityQuery, getResolverName, id, skip, onDoesNotExist) => {
    const response = useQuery(getEntityQuery, {
        variables: { id },
        skip,
        onError(error) {
            const message = error?.message;

            if (message && message.includes('matching query does not exist')) {
                onDoesNotExist();
                return;
            }

            warning(error);
        },
    });

    return useMemo(
        () => ({
            ...response,
            data: response.data?.[getResolverName] ?? null,
        }),
        [response, getResolverName]
    );
};

// All hooks that do things with a project are wrapped inside this component
// that will be included when needed. Using a component instead of just a hook
// is to overcome the hooks cannot be optional violation.
const OptionalParentDataLink = (dataLinkProps) => {
    const { queries, convertDataIntoFormValues, setValues, setParentValues } = dataLinkProps;

    const { getParentEntityQuery, getParentResolverName, parentEntityFieldName } = queries;

    const unsetProjectInValues = useCallback(() => {
        setValues((old) => ({
            ...old,
            project: null,
        }));
    }, [setValues]);

    const parentEntityId = useWatch({
        defaultValue: null,
        name: parentEntityFieldName,
        disable: !parentEntityFieldName,
    });

    const { data: parentEntityData } = useEntity(
        getParentEntityQuery,
        getParentResolverName,
        parentEntityId,
        !getParentEntityQuery || !parentEntityId,
        // Reset the project if it does not exist.
        unsetProjectInValues
    );

    useEffect(() => {
        if (!parentEntityId) {
            unsetProjectInValues();
        }
    }, [parentEntityId, unsetProjectInValues]);

    useEffect(() => {
        setParentValues(parentEntityData && convertDataIntoFormValues(parentEntityData));
    }, [parentEntityData, setParentValues, convertDataIntoFormValues]);
    return null;
};

const GraphQLDataLink = (dataLinkProps) => {
    const {
        queries,
        pk,
        setOnSubmitFunction,
        convertDataIntoFormValues,
        setErrorOccurred,
        setError,
        setLoading,
        setValues,
        setOverriddenFields,
        onSubmitSuccessful,
        readOnlyFields,
        formSchema,
    } = dataLinkProps;

    const {
        objectName,
        getEntityQuery,
        getResolverName,
        updateEntityQuery,
        updateEntityQueryName,
        createEntityQuery,
        createEntityQueryName,
        getParentEntityQuery,
    } = queries;

    const isAddMode = !pk;

    // If there is a parent entity query, this entity has an optional parent (project).
    const entityHasOptionalParent = !!getParentEntityQuery;

    const setMutationErrors = useCallback(
        (mutationErrors) => {
            mutationErrors.forEach(({ field, message }, index) => {
                setError(field ?? `${NON_FIELD_ERRORS}.${index}`, {
                    type: 'manual',
                    message,
                });
            });
        },
        [setError]
    );

    const [mutationQuery, mutationQueryName] = isAddMode
        ? [createEntityQuery, createEntityQueryName]
        : [updateEntityQuery, updateEntityQueryName];

    const onCompleted = useCallback(
        (result) => {
            const data = result?.[mutationQueryName];

            const mutationErrors = data?.errors;

            if (!isEmpty(mutationErrors)) {
                setMutationErrors(mutationErrors);
                return;
            }

            onSubmitSuccessful({
                id: data[objectName].id,
            });
        },
        [mutationQueryName, objectName, setMutationErrors, onSubmitSuccessful]
    );

    const onError = useCallback(
        (error) => {
            warning(error);
            setErrorOccurred(true);
        },
        [setErrorOccurred]
    );

    const [submitMutate] = useMutation(mutationQuery, { onCompleted, onError });

    const onSubmit = useCallback(
        (values) => {
            const payload = cloneDeep(values);

            readOnlyFields.forEach((name) => {
                delete payload[name];
            });

            delete payload.sensor;
            delete payload.swarmType;

            if (entityHasOptionalParent) {
                payload.overriddenFields = extractEnabledFields(payload.override);
            }
            delete payload.override;

            if (has(payload, 'project') && payload.project === '') {
                payload.project = null;
            }

            delete payload.permission;

            // Convert alarmLevelSettings to valid schema fields and fill
            // undefined alarm level fields.
            // Delete the alarmLevelSettings property.
            if (has(payload, 'alarmLevelSettings')) {
                payload.alarmLevelSettings = range(3).forEach((index) => {
                    const level = index + 1;
                    payload[`alarmLevel${level}Name`] =
                        payload.alarmLevelSettings[index]?.name ?? '';
                    payload[`alarmLevel${level}`] = payload.alarmLevelSettings[index]?.value ?? 0;
                });
                delete payload.alarmLevelSettings;
            }

            // Set a default value of 0 for (null)/('') values.
            if (has(payload, 'dailyValueSettings')) {
                payload.dailyValueSettings.triggers.forEach((item, index) => {
                    if (!item.trigger) payload.dailyValueSettings.triggers[index].trigger = 0;
                    if (!item.days) payload.dailyValueSettings.triggers[index].days = 0;
                });
            }

            if (has(payload, 'alarmRecipients')) {
                payload.alarmRecipients = compact(
                    payload.alarmRecipients.map((items) => {
                        const address = items.address.identity;
                        if (!address) return null;
                        let type;
                        if (isEmailValid(address)) {
                            type = 'EMAIL';
                        } else if (isPhoneNumberValid(address)) {
                            type = 'PHONE_NUMBER';
                        } else {
                            // This error should have been catched by the form validation.
                            throw new Error(
                                `Unable to detect email address or phone number in '${address}'.`
                            );
                        }
                        items.address.type = type;
                        return items;
                    })
                );
            }
            if (has(payload, 'ldenGuidelineChoiceType')) {
                if (payload.ldenGuidelineChoiceType !== 'LdenGuidelineCustom') {
                    delete payload.ldenMeasurementSettings;
                }
            }

            // Apply unit conversions.
            Object.keys(payload).forEach((name) => {
                try {
                    const field = formSchema.fields.getByName(name);
                    if (field.displayInSeconds) {
                        payload[name] *= 1000;
                    }
                } catch (error) {
                    // Some fields in the form do not exist in the backend schema.
                    // We expect these to be not found.
                    if (!(error instanceof FieldNotFoundError)) {
                        throw error;
                    }
                }
            });

            const mutationOptions = {
                variables: {
                    input: payload,
                },
            };

            if (!isAddMode) {
                // Add ID for the update.
                mutationOptions.variables.input.id = pk;
            }

            submitMutate(mutationOptions);
        },
        [isAddMode, entityHasOptionalParent, pk, submitMutate, readOnlyFields, formSchema.fields]
    );

    useEffect(() => {
        setOnSubmitFunction(() => onSubmit);
    }, [setOnSubmitFunction, onSubmit]);

    const { loading: getEntityLoading, data: entityData } = useEntity(
        getEntityQuery,
        getResolverName,
        pk,
        isAddMode,
        () => {
            setErrorOccurred(true);
        }
    );

    useEffect(() => {
        setLoading(() => getEntityLoading);
    }, [setLoading, getEntityLoading]);

    // Set form values when we received the data.
    useEffect(() => {
        if (entityData) {
            setValues(convertDataIntoFormValues(entityData));

            if (entityHasOptionalParent) {
                setOverriddenFields([...entityData.overriddenFields]);
            }
        }
    }, [
        entityData,
        entityHasOptionalParent,
        setOverriddenFields,
        setValues,
        convertDataIntoFormValues,
    ]);

    return <>{getParentEntityQuery && <OptionalParentDataLink {...dataLinkProps} />}</>;
};

export default GraphQLDataLink;
